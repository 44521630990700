import { Grid } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import {
  setCompanyId,
  setDirectCustomersList,
  setResellersList,
} from '../../../store/slice/homeSlice'
import { getAllResellers, getAllcustomers } from '../../../api/get/getApi'
import { useSearchParams } from 'react-router-dom'
import Reseller from '../reseller/Reseller'
import DirectCustomer from '../customer/DirectCustomer'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import {
  executeWithTryCatch,
  gettingCompanyId,
} from '../../../helpers/HelperFunctions'
import { useHomeRedux } from '../Home'
import { setAllProducts } from '../../../store/slice/commonSlice'
import { useToastContext } from '../../../App'
import ServiceProviderAnalytics from './components/ServiceProviderAnalytics'

const ServiceProvider = (props) => {
  // hooks
  const { dispatch, state } = useHomeRedux()
  const { toast } = useToastContext()
  const [searchparams] = useSearchParams()
  const [loading, setLoading] = useState(false)

  //lifeCycles
  useEffect(() => {
    state.serviceProviderTrigger === 'triggered' &&
      fetchResellerAndCustomerList()
  }, [state.serviceProviderTrigger])

  const fetchResellerAndCustomerList = () => {
    const companyId = searchparams.get('company_id') || gettingCompanyId()
    setLoading(true)
    dispatch(setAllProducts(null))
    dispatch(setCompanyId(companyId))
    executeWithTryCatch(getResellersListApi, handleError, companyId)
    executeWithTryCatch(getCustomersListApi, handleError, companyId)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const getResellersListApi = async (serviceProviderId) => {
    const response = await getAllResellers(serviceProviderId)
    dispatch(setResellersList(response.data.result))
  }

  const getCustomersListApi = async (parentId) => {
    const response = await getAllcustomers(parentId)
    dispatch(setDirectCustomersList(response.data.result))
  }

  const handleError = (error) => {
    toast.showToast(error?.response?.data?.message, 'error')
  }

  return loading ? (
    <SkeletonTableLoader></SkeletonTableLoader>
  ) : (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <ServiceProviderAnalytics
          resellersList={state.resellersList}
          directCustomersList={state.directCustomersList}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {state.toggleReseller && (
          <Reseller
            onRowClick={(row) => props.onRowClick(row, 'reseller')}
            handleShowCompany={props.handleShowCompany}
          />
        )}
        {state.toggleCustomer && (
          <DirectCustomer
            onRowClick={(row) => props.onRowClick(row, 'customer', true)} //direct customer : true
            handleShowCompany={props.handleShowCompany}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default memo(ServiceProvider)
