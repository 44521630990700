import { useEffect, useState } from 'react'
// mui
import { Grid, Box, Container, Paper } from '@mui/material'
import jwt_decode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import { masterConfigurationTabGetApi } from '../../../api/master/masterAPI'
import MSRP from './components/MSRP'
import { getCompanyLevel } from '../../../helpers/HelperFunctions'
import { useToastContext } from '../../../App'

function MSRPSettings(props) {
  const { toast } = useToastContext();
  const [loader, setLoader] = useState(false)
  const [hideEdit, setHideEdit] = useState(false)
  const [configData, setConfigData] = useState()
  const [searchParams] = useSearchParams()
  const getCustomerConfigApi = (id) => {
    if (id) {
      setLoader(true)
      masterConfigurationTabGetApi(
        id,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          setLoader(false)
          if (response.data.result) {
            setConfigData(response.data.result)
          }
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }
  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getCustomerConfigApi(companyId)
    }
  }

  const refreshData = (message) => {
    if (message) {
      toast.showToast(message?.data?.message, 'success')
      getDecodeValue()
    }
  }

  useEffect(() => {
    getDecodeValue()
  }, [])
  return (
    <div className="Dash_main_container">
      <Container maxWidth="xl">
        <Box>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item md={6} sm={6} xs={6}>
              <Paper
                elevation={6}
                style={{ minHeight: '500px' }}
                className="paper1"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 0 0 20px',
                  }}
                >
                  <div style={{ fontSize: '16px', fontWeight: '600' }}>
                    Setting Configuration
                  </div>
                  <div></div>
                </div>

                {loader ? (
                  <SkeletonTableLoader></SkeletonTableLoader>
                ) : (
                  <>
                    <MSRP
                      configData={configData}
                      refreshData={refreshData}
                      hideEdit={hideEdit}
                      setHideEdit={setHideEdit}
                    ></MSRP>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}
export default MSRPSettings
