import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import React, { forwardRef } from 'react'

const ThemeButton = (props, _ref) => {
  const { text, isLoadButton = false, ...buttonProps } = props
  return isLoadButton ? <LoadingButton
    loading={buttonProps?.loading}
    loadingPosition={buttonProps?.loadingPosition || "end"}
    {...buttonProps}
  >
    Submit
  </LoadingButton> : <Button {...buttonProps}>{text}</Button>
}

export default forwardRef(ThemeButton);
