import React, { useState } from 'react'

// mui
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material'

// react-router
import { useLocation, useSearchParams } from 'react-router-dom'

// jwt - decode
import jwt_decode from 'jwt-decode'

// formik
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// styles
import '../../../pages/auth/login/login.css'

// icons

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// sso
import { commonLogin } from '../../../api/post/postApi'
import ReusableTextfield from '../../../common/components/textField/textField'
import { masterAdminResetPasswordApi } from '../../../api/master/masterAPI'
import {
  decodeToken,
  getCompanyLevel,
  getToken,
  saveToken,
} from '../../../helpers/HelperFunctions'
import { useToastContext } from '../../../App'

// formik validations
const initialValues = {
  email: '',
  password: '',
}

const FormValidation = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email.')
    .required('Please enter your email'),
  password: Yup.string().required('Please enter your password'),
})

function ReLoginPage(props) {
  const { toast } = useToastContext()

  const [showPassword, setshowPassword] = useState(false)
  const [nextStep, setNextStep] = useState(1)
  const [loader, setLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  }

  const [loading, isLoading] = useState(false)

  const AccountLogin = async (values) => {
    isLoading(true)
    try {
      await commonLogin(values)
        .then((res) => {
          if (res) {
            loginFunction(res)
          }
        })
        .catch((error) => {
          toast.showToast(error.response.data.message, 'error')
          isLoading(false)
        })
    } catch (err) {
      toast.showToast(err, 'error')
    }
  }

  const loginFunction = (res) => {
    try {
      const { token } = res.data.result
      saveToken(token)
      const decodedToken = decodeToken(token)
      if (decodedToken) {
        localStorage.setItem('companyname', decoded.company_name)
        setNextStep(1)
      }
    } catch (err) {
      toast.showToast(err, 'error')
    }
  }
  const nextStepReset = () => {
    setLoader(true)
    masterAdminResetPasswordApi(
      { emailAddress: props.userDetails?.email },
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((resp) => {
        setLoader(false)
        setNextStep(2)
        toast.showToast(resp.data.message)
      })
      .catch((err) => {
        setLoader(false)
      })
  }

  return (
    <>
      {nextStep == 0 ? (
        <div>
          <Formik
            initialValues={{
              email: props?.resetDetails.user_emailaddress,
            }}
            validationSchema={FormValidation}
            onSubmit={(values) => {
              AccountLogin(values)
            }}
          >
            <Form>
              <div style={{ padding: '20px', marginTop: '-20px' }}>
                <Grid container rowSpacing={{ lg: 3, md: 3, sm: 2, xs: 1 }}>
                  <Grid item md={12} sm={12} xs={12}>
                    <ReusableTextfield
                      variant="outlined"
                      size="small"
                      id="Email"
                      name="email"
                      value={props.userDetails.email}
                      disabled={true}
                      autoComplete="off"
                      InputProps={{
                        style: { height: '30px', fontSize: '12px' },
                      }}
                      FormHelperTextProps={{ style: { fontSize: '12px' } }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <ReusableTextfield
                      size="small"
                      id="Password"
                      name="password"
                      autoComplete="off"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        style: { height: '30px', fontSize: '12px' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ style: { fontSize: '12px' } }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box style={{ float: 'right' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          borderRadius: '70px',
                          width: '100px',
                        }}
                      >
                        Reset{' '}
                        {loading ? (
                          <>
                            {' '}
                            &nbsp;{' '}
                            <CircularProgress
                              size={20}
                              sx={{ color: '#fff' }}
                            />{' '}
                          </>
                        ) : null}{' '}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Form>
          </Formik>
        </div>
      ) : (
        ''
      )}

      {nextStep == 1 ? (
        <div>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            A Reset password email will be sent to the following{' '}
            <b>{props.userDetails?.email} </b>
          </div>
          <Grid item md={12} sm={12} xs={12}>
            <Box style={{ float: 'right' }}>
              <Button
                onClick={() => nextStepReset()}
                type="button"
                variant="contained"
                sx={{
                  borderRadius: '70px',
                  width: '150px',
                }}
              >
                Confirm{' '}
                {loader ? (
                  <>
                    {' '}
                    &nbsp; <CircularProgress
                      size={20}
                      sx={{ color: '#fff' }}
                    />{' '}
                  </>
                ) : null}{' '}
              </Button>
            </Box>
          </Grid>
        </div>
      ) : (
        ''
      )}
      {nextStep == 2 ? (
        <div>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            Reset password has been sent to the following{' '}
            <b>{props.userDetails?.email} </b>
          </div>
          <Grid item md={12} sm={12} xs={12}>
            <Box style={{ float: 'right' }}>
              <Button
                onClick={() => props.handleClose()}
                type="button"
                variant="contained"
                sx={{
                  borderRadius: '70px',
                  width: '100px',
                }}
              >
                Close{' '}
              </Button>
            </Box>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
export default ReLoginPage
