import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/material'
import React from 'react'
import unauthorised from '../../../../assets/403.svg'

function UnAuthorised() {
  return (
    <Stack justifyContent={'center'} alignItems="center" height="100vh">
      <img src={unauthorised} height={'400'} width={'500'} />

      <Typography>Sorry, the page you visited is forbidden.</Typography>
      {/*<Button onClick={() => history.replace("/login")}>Retry</Button>*/}
    </Stack>
  )
}

export default UnAuthorised
