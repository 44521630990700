import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Tooltip,
  Drawer,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
} from '@mui/material'

import { useLocation, useSearchParams } from 'react-router-dom'

// import EditIcon from '@mui/icons-material/Edit'
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material'
import MasterCardLogo from '../../../assets/Mastercard-Logo.png'
import VisaCardLogo from '../../../assets/VISA-Logo.png'
import AmericanExpressCardLogo from '../../../assets/American-Express.png'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import jwt_decode from 'jwt-decode'
import {
  paymentDeleteApi,
  paymentGetAllApi,
} from '../../../api/payment/paymentAPI'
import AddNewPaymentCard from './paymentCardAdd'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import AddNewPaymentBank from './paymentBankAdd'
import EditPaymentCard from './paymentCardEdit'
import ConfirmationModal from '../../../common/components/custom/modal/modal'
import LoadingSection from '../../../common/components/loaders/loader'
import moment from 'moment/moment'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import ThemeButton from '../../../common/components/button/Button'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'
import DrawerHeader from '../../../common/components/drawer/DrawerHeader'

function Payments(props) {
  const { toast } = useToastContext()
  const { dispatch, state: commonState } = useCommonRedux()
  const [searchParams] = useSearchParams()
  const [rows, setRowsList] = useState([])
  const [edited, setEdited] = useState('')
  const [details, setDetails] = useState('')
  const [methodValue, setMethodValue] = useState('card')
  const [loader, setLoader] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [state, setState] = useState({
    right: false,
  })
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleDelete = (id) => {
    setSubmitLoader(true)
    paymentDeleteApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setSubmitLoader(false)
        toast.showToast(response.data.message)
        getDecodeValue()
        handleClose()
        toggleDrawer('right', false)
      })
      .catch((error) => {
        toast.showToast(error.response.data.message, 'error')
        setSubmitLoader(false)
      })
  }

  const getAllList = (id) => {
    setLoader(true)
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    let companyId = searchParams.get('company_id')
    if (!companyId) {
      companyId = decoded?.company_id
    }
    paymentGetAllApi(
      companyId,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setLoader(false)
        setRowsList(response.data.result)
      })
      .catch((error) => {
        setLoader(false)
      })
  }
  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      getAllList(decoded?.company_id)
    }
  }
  const toggleDrawer = (
    anchor,
    open,
    switchVal,
    details,
    load,
    alertMessage
  ) => {
    if (load) {
      getDecodeValue()
    }
    if (alertMessage) {
      toast.showToast(alertMessage.data.message)
    }
    setDetails(details)
    setEdited(switchVal)
    setState({ ...state, [anchor]: open })
  }
  // const gotoDrawer = (event, details) => {
  //   if (details) {
  //     setDetails(details)
  //   }
  //   setEdited(event)
  // }

  useEffect(() => {
    getDecodeValue()
  }, [])

  // const changeMethod = (event) => {
  //   setMethodValue(event.target.value)
  // }
  const checkAddEdit = (item, index) => {
    switch (item) {
      case 'add':
        return (
          <>
            {methodValue == 'card' ? (
              <AddNewPaymentCard toggleDrawer={toggleDrawer} />
            ) : (
              ''
            )}
            {methodValue == 'bank' ? (
              <AddNewPaymentBank toggleDrawer={toggleDrawer} />
            ) : (
              ''
            )}
          </>
        )
      case 'edit':
        return (
          <>
            {details?.method == 'CC' ? (
              <EditPaymentCard details={details} toggleDrawer={toggleDrawer} />
            ) : (
              ''
            )}
            {/* {details?.method == 'ACH' ?  <EditPaymentBank details={details} toggleDrawer={toggleDrawer}/> : ''} */}
          </>
        )
    }
  }

  const renderCreditCardLogo = (brand) => {
    switch (brand?.toUpperCase()) {
      case 'VISA':
        return VisaCardLogo
      case 'MASTER':
        return MasterCardLogo
      case 'AMEX':
        return AmericanExpressCardLogo
      default:
        return ''
    }
  }
  
  return (
    <div>
      <Container
        maxWidth="xl"
        sx={{
          borderRadius: '12px',
          boxShadow:
            '0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          marginTop: '15px',
        }}
      >
        {submitLoader ? <LoadingSection></LoadingSection> : ''}
        <Grid container>
          <React.Fragment>
            <Drawer
              BackdropProps={{ invisible: true }}
              PaperProps={{
                sx: { width: '35%' },
              }}
              anchor={'right'}
              open={state['right']}
            >
              <div
                style={{
                  //display: 'flex',
                  width: '100%',
                  marginBottom: '10px',
                  //padding: '0 5px 0 10px',
                }}
              >
                {edited == 'edit' ? (
                  <h3
                    style={{
                      display: 'flex',
                      marginTop: '-5px',
                      marginBottom: '0',
                    }}
                  >
                    <div style={{ marginLeft: '10px' }}>
                      Payment Details
                      {checkPermission(
                        [
                          PERMISSIONS.masterBillingDelete,
                          PERMISSIONS.serviceproviderBillingDelete,
                          PERMISSIONS.resellerBillingDelete,
                          PERMISSIONS.customerBillingDelete,
                        ],
                        commonState.currentCompanyDetails
                      ) ? (
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              fontSize: '15px',
                              color: ' #000000a1',
                              cursor: 'pointer',
                            }}
                            onClick={() => setIsOpen(true)}
                          >
                            <PersonRemoveIcon
                              style={{
                                fontSize: '12px',
                                verticalAlign: 'middle',
                                margin: '0 5px 0 0',
                              }}
                            ></PersonRemoveIcon>
                            Delete Payment
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </h3>
                ) : (
                  ''
                )}

                {edited == 'add' ? (
                  <>
                    <DrawerHeader
                      header="Add New Payment Method"
                      onClick={() => toggleDrawer('right', false)}
                    />
                    <div style={{ padding: '10px 20px' }}>
                      <span>Add Credit Card or Debit Card</span>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              {checkAddEdit(edited)}
              <ConfirmationModal
                handleConfirm={() => handleDelete(details?.paymentAccountId)}
                title="Confirmation"
                content={`Are you sure you want to delete this payment [ ${
                  details?.bankAccount?.accountName
                    ? details?.bankAccount?.accountName
                    : ''
                } 
                            ${
                              details?.cardAccount?.firstName
                                ? details?.cardAccount?.firstName
                                : ''
                            } ${
                  details?.cardAccount?.lastName
                    ? details?.cardAccount?.lastName
                    : ''
                } ] ?`}
                isOpen={isOpen}
                handleClose={handleClose}
              />
            </Drawer>
          </React.Fragment>
          <Grid item md={12} sm={12} xs={12}>
            <Box sx={{ m: '20px' }}>
              <h2>
                Payment Details
                {checkPermission(
                  [
                    PERMISSIONS.masterBillingCreate,
                    PERMISSIONS.serviceproviderBillingCreate,
                    PERMISSIONS.resellerBillingCreate,
                    PERMISSIONS.customerBillingCreate,
                  ],
                  commonState.currentCompanyDetails
                ) ? (
                  <ThemeButton
                    variant="contained"
                    text="Add New Payment"
                    onClick={() => toggleDrawer('right', true, 'add')}
                    startIcon={<AddIcon />}
                    sx={{
                      float: 'right',
                      height: '32px',
                    }}
                  ></ThemeButton>
                ) : (
                  ''
                )}
              </h2>
              {loader ? (
                <SkeletonTableLoader></SkeletonTableLoader>
              ) : (
                <>
                  {rows.length > 0 ? (
                    <Grid container spacing={2}>
                      {rows.map((row, index) => {
                        return (
                          <>
                            <Grid item md={3} sm={6} xs={12}>
                              <Box className="credit-cards-container">
                                <Box
                                  className={
                                    row.method == 'CC'
                                      ? 'credit-card-row1'
                                      : 'credit-cards-container2-row1'
                                  }
                                >
                                  {row.method == 'CC' && (
                                    <img
                                      src={ renderCreditCardLogo(row.cardAccount?.brand) }
                                      width="60px"
                                      height="30px"
                                      style={{ marginLeft: '20px' }}
                                      alt="master card"
                                    ></img>
                                  )}
                                  <span
                                    style={{ paddingLeft: '20px' }}
                                    className="credit-card-cvv-style"
                                  >
                                    {row.method == 'CC' ? 'Card Details' : ''}
                                    {/* {row.method == 'ACH' ? 'ACH Profile' : ''} */}
                                  </span>
                                </Box>
                                {row.method == 'CC' && (
                                  <Box className="credit-cards-row2">
                                    <span style={{ paddingLeft: '20px' }}>
                                      {row?.cardAccount?.firstName}{' '}
                                      {row?.cardAccount?.lastName}
                                    </span>
                                    <span style={{ paddingRight: '20px' }}>
                                      {moment(
                                        row?.cardAccount?.expriationDate
                                      ).format('MM/YYYY')}{' '}
                                    </span>
                                  </Box>
                                )}

                                {row.method == 'ACH' ? (
                                  <Box className="credit-cards-row2">
                                    <span style={{ paddingLeft: '20px' }}>
                                      {row?.bankAccount?.accountName}
                                    </span>
                                    <span style={{ paddingRight: '20px' }}>
                                      {row?.bankAccount?.routingNumber}{' '}
                                    </span>
                                  </Box>
                                ) : (
                                  ''
                                )}

                                <Box className="credit-cards-row3">
                                  <span className="default-payment-rowstyle">
                                    ***********{row.last4}
                                  </span>
                                </Box>
                                {checkPermission(
                                  [
                                    PERMISSIONS.masterBillingUpdate,
                                    PERMISSIONS.serviceproviderBillingUpdate,
                                    PERMISSIONS.resellerBillingUpdate,
                                    PERMISSIONS.customerBillingUpdate,
                                  ],
                                  commonState.currentCompanyDetails
                                ) ? (
                                  <Box className="card-hover-design">
                                    <Box className="overlay">
                                      <Tooltip
                                        placement="top"
                                        title="Edit Details"
                                      >
                                        <Box className="text">
                                          <EditIcon
                                            onClick={() =>
                                              toggleDrawer(
                                                'right',
                                                true,
                                                'edit',
                                                row
                                              )
                                            }
                                            className="creditCardEditIcon"
                                          />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </Box>
                            </Grid>
                          </>
                        )
                      })}
                    </Grid>
                  ) : (
                    <p className="slight-gray-style">
                      There is no payment methods for your company.
                    </p>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Payments
