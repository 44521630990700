import { Box, Checkbox, FormControlLabel, Grid, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  formDetailsState,
  setIsTermsAndConditionsAccepted,
  setService,
  setTermsAndConditionsBase64String,
  StepBackward,
  StepForward,
} from '../../../../../store/slice/stepperSlice'
import {
  convertBase64ToHtml,
  executeWithTryCatch,
  gettingCompanyId,
} from '../../../../../helpers/HelperFunctions'
import nextbtn from '../../../../../assets/icons/Next Button.svg'
import backbtn from '../../../../../assets/icons/Back Button.svg'
import { useToastContext } from '../../../../../App'
import { TOAST_MESSAGES } from '../../../../../helpers/Constants'
import { getCompanyNameStepper } from '../../../../../api/get/getApi'
import { useCommonRedux } from '../../../../../store/middlewares/CommonRedux'
import { setSkeletonLoading } from '../../../../../store/slice/commonSlice'
import SkeletonTableLoader from '../../../../../common/components/loaders/skeletonLoader'
import {
  BorderBox,
  TermsAndConditionBorderBox,
} from '../../../../../common/styles/styled-components/StyledContainers'

const ConsentAndAcknowledgement = () => {
  const { dispatch, state: commonState } = useCommonRedux()
  const { toast } = useToastContext()
  const stepperState = useSelector(formDetailsState);

  useEffect(() => {
    if (!stepperState.termsAndConditionBase64String) {
      executeWithTryCatch(getCompanyDraft, handleError)
    }
  }, [])

  const getCompanyDraft = async () => {
    dispatch(setSkeletonLoading(true))
    const localToken = localStorage.getItem('sd_cp.jwt:tkn')
    const config = { headers: { Authorization: `Bearer ${localToken}` } }
    const {
      data: { result },
    } = await getCompanyNameStepper(gettingCompanyId(), config)
    dispatch(setService(result?.service))
    dispatch(
      setTermsAndConditionsBase64String(result?.termsAndConditionsFileData)
    )
    dispatch(
      setIsTermsAndConditionsAccepted(result?.isTermsAndConditionsAccepted)
    )
    dispatch(setSkeletonLoading(false))
  }

  //Error
  const handleError = (error) => {
    dispatch(setSkeletonLoading(false))
    toast.showToast(error?.response?.data?.message, 'error')
  }

  const handleAcceptTermsAndCondition = (e) =>
    dispatch(setIsTermsAndConditionsAccepted(e.target.checked))

  const handleSubmitTermsAndCondition = () => {
    if (stepperState.isTermsAndConditionsAccepted) {
      dispatch(StepForward())
    } else {
      toast.showToast(TOAST_MESSAGES.AcceptTermsAndCondition, 'error')
    }
  }

  return (
    <Grid container sx={{ display: 'flex' }}>
      {stepperState?.termsAndConditionBase64String && (
        <BorderBox>
          {commonState?.isSkeletonLoading ? (
            <SkeletonTableLoader />
          ) : (
            convertBase64ToHtml(stepperState?.termsAndConditionBase64String)
          )}
        </BorderBox>
      )}

      <Grid item md={12} sm={12} xs={12} sx={{ paddingLeft: '10px' }}>
        <FormControlLabel
          onChange={handleAcceptTermsAndCondition}
          control={<Checkbox defaultChecked={stepperState.isTermsAndConditionsAccepted} />}
          label="I Agree"
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Stack direction="row" justifyContent="flex-start">
          <img
            src={backbtn}
            className="btn-hover-effect"
            height="92px"
            onClick={() => {
              dispatch(StepBackward())
            }}
            alt="next button"
          />
          <img
            src={nextbtn}
            className="btn-hover-effect"
            height="92px"
            onClick={handleSubmitTermsAndCondition}
            alt="next button"
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ConsentAndAcknowledgement
