import { useEffect } from 'react'
// mui
import { Grid, Container } from '@mui/material'
import MasterServices from './components/MasterServices'
import MasterProfile from './components/MasterProfile'
import { genericDetailsApi } from '../../../api/get/getApi'
import { useSearchParams } from 'react-router-dom'
import { masterDetailsApi } from '../../../api/master/masterAPI'
import { getStates, getCountries } from '../../../api/get/getApi'
import { CompanySkeleton } from '../../../common/components/loaders/skeletons/SettingsSkeleton'
import {
  setCountriesList,
  setSkeletonLoading,
  setStatesList,
  setCurrentCompanyDetails,
  setCompanyId,
} from '../../../store/slice/commonSlice'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'
import {
  checkPermission,
  getCompanyLevel,
  gettingCompanyId,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'

export default function SettingsCompany(props) {
  const { dispatch, state } = useCommonRedux()
  const [searchParams, setSearchParams] = useSearchParams()
  const companyId = gettingCompanyId()
  const currentLevel = getCompanyLevel(
    searchParams.get('company_id'),
    location.pathname.slice(1)
  )

  useEffect(() => {
    getDetailsAPI()
  }, [])

  const getDetailsAPI = async () => {
    try {
      let response
      dispatch(setSkeletonLoading(true))
      if (currentLevel?.toLowerCase() == 'master') {
        response = await masterDetailsApi()
      } else {
        response = await genericDetailsApi(companyId, currentLevel)
      }
      dispatch(setCompanyId(companyId))
      dispatch(setCurrentCompanyDetails(response.data.result))
      const countriesResponse = await getCountries()
      dispatch(setCountriesList(countriesResponse.data?.result))
      const countryCode = countriesResponse.data.result.find(o => o.name === response.data.result?.country)?.code;
      const statesResponse = await getStates(countryCode || response.data.result?.country)
      dispatch(setStatesList(statesResponse.data?.result))

      dispatch(setSkeletonLoading(false))
    } catch (error) {
      dispatch(setSkeletonLoading(false))
    }
  }

  return (
    <div className="Dash_main_container">
      <Container maxWidth="xl">
        {!state.currentCompanyDetails ? (
          <CompanySkeleton />
        ) : (
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item md={6} sm={12} xs={12}>
              <MasterProfile />
            </Grid>
            {checkPermission(
              [
                // PERMISSIONS.masterProductView, //hide services for master
                PERMISSIONS.serviceproviderProductView,
                PERMISSIONS.resellerProductView,
                PERMISSIONS.customerProductView,
              ],
              state.currentCompanyDetails
            ) && (
                <Grid item md={6} sm={12} xs={12}>
                  <MasterServices />
                </Grid>
              )}
          </Grid>
        )}
      </Container>
    </div>
  )
}
