import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  FormGroup,
} from '@mui/material';
import * as React from 'react'
import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  masterNotificationTemplateDraftPostApi,
  masterNotificationTemplatePostApi,
  masterNotificationTemplateTestApi,
  masterNotificationTemplateUpdateApi,
} from '../../../../api/master/masterAPI';
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ListItemIcon from '@mui/material/ListItemIcon'
import MailIcon from '@mui/icons-material/Mail'
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo'
import { getCompanyLevel } from '../../../../helpers/HelperFunctions'
import { useToastContext } from '../../../../App'
const companyLevelList = [
  {
    id: 2,
    name: 'Service Provider',
    isAssigned: true,
  },
  {
    id: 3,
    name: 'Reseller',
    isAssigned: true,
  },
  {
    id: 4,
    name: 'Direct Customers(*Excludes Customers who purchase from Resellers)',
    isAssigned: true,
  },
]
const contactTypeList = [
  {
    id: 1,
    name: 'Primary',
    isAssigned: true,
  },
  {
    id: 2,
    name: 'Billing',
    isAssigned: true,
  },
]
let minDate = new Date()
minDate.setDate(minDate.getDate() + 1)
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]
export function NotificationCreate(props) {
  const { toast } = useToastContext();
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [showUpdate, setShowUpdate] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [touched, setTouched] = React.useState(true)
  const [scheduledDateTime, setScheduledDateTime] = React.useState(null)
  const [companyLevel, setCompanyLevel] = React.useState(companyLevelList)
  const [contactType, setContactType] = React.useState(contactTypeList)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [value, setValue] = useState('')
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/

  const formik = useFormik({
    initialValues: {
      subject: '',
      body: '',
      email: '',
      isSentNow: false,
      isEmail: false,
      isApp: false,
      isCancel: false,
      scheduledDateTime: '',
      companyLevel: [],
      contactType: [],
    },
    initialTouched: false,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      const listMapCompany = companyLevel.map((dat) => {
        if (dat.isAssigned) {
          return dat.id
        }
      })
      const listMapContact = contactType.map((dat) => {
        if (dat.isAssigned) {
          return dat.id
        }
      })
      data.companyLevel = listMapCompany.filter((dat) => dat)
      data.contactType = listMapContact.filter((dat) => dat)
      if (data.isSentNow) {
        delete data.scheduledDateTime
      }
      masterNotificationTemplatePostApi(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          props.getDecodeValue()
          setSubmitLoader(false)
          setShowEdit(false)
          props?.setHideEdit(false)
          props.refreshData(response)
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast(error?.response?.data?.message, 'error')
        })
    },
  })
  const saveDraft = (name) => {
    setSubmitLoader(true)
    const data = formik.values
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    let companyId = searchParams.get('company_id')
    if (!companyId) {
      companyId = decoded?.company_id
    }
    data.companyId = companyId
    const listMapCompany = companyLevel.map((dat) => {
      if (dat.isAssigned) {
        return dat.id
      }
    })
    const listMapContact = contactType.map((dat) => {
      if (dat.isAssigned) {
        return dat.id
      }
    })
    data.companyLevel = listMapCompany.filter((dat) => dat)
    data.contactType = listMapContact.filter((dat) => dat)
    if (data.isSentNow) {
      delete data.scheduledDateTime
    }
    if (data?.scheduledDateTime == '') {
      delete data.scheduledDateTime
    }

    if (name == 'update') data.id = props.detailsData?.id
    masterNotificationTemplateDraftPostApi(
      data,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        props.getDecodeValue()
        setSubmitLoader(false)
        setShowEdit(false)
        props?.setHideEdit(false)
        props.refreshData(response)
      })
      .catch((error) => {
        setSubmitLoader(false)
        toast.showToast(error?.response?.data?.message, 'error')
      })
  }
  const updateForm = () => {
    setSubmitLoader(true)
    const data = formik.values
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    let companyId = searchParams.get('company_id')
    if (!companyId) {
      companyId = decoded?.company_id
    }
    data.companyId = companyId
    const listMapCompany = companyLevel.map((dat) => {
      if (dat.isAssigned) {
        return dat.id
      }
    })
    const listMapContact = contactType.map((dat) => {
      if (dat.isAssigned) {
        return dat.id
      }
    })
    data.companyLevel = listMapCompany.filter((dat) => dat)
    data.contactType = listMapContact.filter((dat) => dat)
    data.id = props.detailsData?.id
    if (data.isSentNow) {
      delete data.scheduledDateTime
    }
    if (!data.isSentNow && !data.scheduledDateTime) return
    masterNotificationTemplateUpdateApi(
      data,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        props.getDecodeValue()
        setSubmitLoader(false)
        setShowEdit(false)
        setShowUpdate(false)
        props?.setHideEdit(false)
        props.refreshData(response)
      })
      .catch((error) => {
        setSubmitLoader(false)
        toast.showToast(error?.response?.data?.message, 'error')
      })
  }

  const showEditButton = (e) => {
    if (e) {
    } else {
      formik.resetForm()
    }
    setShowEdit(e)
  }
  const sendTestNotificationMail = () => {
    const data = {
      email: formik.values.email,
      subject: formik.values.subject,
      body: formik.values.body,
    }

    if (reg.test(formik.values.email)) {
      setSubmitLoader(true)
      masterNotificationTemplateTestApi(data)
        .then((response) => {
          setSubmitLoader(false)
          toast.showToast(response?.data?.message, 'success')
        })
        .catch((error) => {
          setSubmitLoader(false)

          toast.showToast(error?.response?.data?.message, 'error')
        })
    } else {
      toast.showToast('Please enter valid email', 'error')
    }
  }

  const handleCompanyPrivileges = (e, ind) => {
    setCompanyLevel((prev) => {
      let newState = [...prev]
      newState[ind].isAssigned = e.target.checked
      return newState
    })
  }

  const handleContactPrivileges = (e, ind) => {
    setContactType((prev) => {
      let newState = [...prev]
      newState[ind].isAssigned = e.target.checked
      return newState
    })
  }
  useEffect(() => {
    if (props.detailsData) {
      setShowUpdate(true)
      if (props.detailsData?.status == 1 || props.detailsData?.status == 2) {
        setShowEdit(false)
      } else {
        setShowEdit(true)
      }

      formik.setFieldValue('subject', props.detailsData?.subject)
      formik.setFieldValue('body', props.detailsData?.body)
      formik.setFieldValue('email', props.detailsData?.email)
      formik.setFieldValue('isSentNow', props.detailsData?.isSentNow)
      formik.setFieldValue('isEmail', props.detailsData?.isEmail)
      formik.setFieldValue('isApp', props.detailsData?.isApp)
      formik.setFieldValue('isCancel', props.detailsData?.isCancel)
      formik.setFieldValue(
        'scheduledDateTime',
        props.detailsData?.scheduledDateTime
      )
      if (props.detailsData?.scheduledDateTime) {
        //   let stillUtc = moment.utc(props.detailsData?.scheduledDateTime).toDate();
        //   const date = moment(stillUtc).local().format('YYYY-MM-DDTHH:mm:ss');
        setScheduledDateTime(props.detailsData?.scheduledDateTime)
      }
      // if(props.detailsData?.sentDateTime){
      //   let stillUtc = moment.utc(props.detailsData?.sentDateTime).toDate();
      //   const date = moment(stillUtc).local().format('YYYY-MM-DDTHH:mm:ss');
      //   formik.setFieldValue("sentDateTime", date);
      // }
      const companyArray = companyLevel.forEach((rol) => {
        if (
          props.detailsData?.companyLevel?.some(
            (com) => com.companyLevel == rol.id
          )
        ) {
          rol.isAssigned = true
        } else {
          rol.isAssigned = false
        }
      })
      formik.setFieldValue('companyLevel', companyArray)
      const contactTypeArray = contactType.forEach((rol) => {
        if (
          props.detailsData?.contactType?.some(
            (com) => com.contactType == rol.id
          )
        ) {
          rol.isAssigned = true
        } else {
          rol.isAssigned = false
        }
      })
      formik.setFieldValue('contactType', contactTypeArray)
    }
  }, [props?.detailsData])
  useEffect(() => {
    dayjs.extend(utc)
    dayjs.utc().isUTC()
    dayjs.extend(timezone)
    dayjs.extend(customParseFormat)
    let stillUtc = moment.utc(minDate).toDate()
    minDate =
      moment(stillUtc).local().format('YYYY-MM-DDTHH:mm:ss') + ':00.000Z'
    formik.errors = {}
  }, [])
  useEffect(() => {
    formik.setTouched({}, touched)
  }, [touched, formik?.errors])

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={6}>
              <Grid container md={12} sm={12} xs={12}>
                <FormControl
                  required
                  error={!(formik.values.isApp || formik.values.isEmail)}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <Box className="stepper-input-label-margin">
                      <div className="stepper-label-style">
                        Notification Method
                      </div>
                      <FormControlLabel
                        label={
                          <ListItemIcon
                            sx={{ mt: '8px', ml: '-5px', mr: '5px' }}
                          >
                            <MailIcon /> Email
                          </ListItemIcon>
                        }
                        control={
                          <Checkbox
                            checked={formik.values.isEmail}
                            name="isEmail"
                            disabled={showEdit}
                            onChange={formik.handleChange}
                            value={formik.values.isEmail}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <ListItemIcon
                            sx={{ mt: '8px', ml: '-5px', mr: '5px' }}
                          >
                            <PersonalVideoIcon /> In App
                          </ListItemIcon>
                        }
                        control={
                          <Checkbox
                            checked={formik.values.isApp}
                            name="isApp"
                            disabled={showEdit}
                            onChange={formik.handleChange}
                            value={formik.values.isApp}
                          />
                        }
                      />

                      {!(formik.values.isApp || formik.values.isEmail) ? (
                        <FormHelperText sx={{ color: 'red !important' }}>
                          At least One Notification Method should be selected
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </Box>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid container md={12} sm={12} xs={12}>
                {props?.detailsData?.status == 3 &&
                  props?.detailsData?.sentDateTime ? (
                  <Box className="stepper-input-label-margin">
                    <div className="stepper-label-style">Sent Date Time</div>
                    <div className="stepper-label-style">
                      {moment(props?.detailsData?.sentDateTime).format(
                        'YYYY MMM, DD - hh:mm a'
                      )}
                    </div>
                  </Box>
                ) : (
                  <Box className="stepper-input-label-margin">
                    <div className="stepper-label-style">Delivery Schedule</div>
                    <FormControlLabel
                      label={'Send it now'}
                      control={
                        <Checkbox
                          checked={formik.values.isSentNow}
                          name="isSentNow"
                          disabled={showEdit}
                          onChange={formik.handleChange}
                          value={formik.values.isSentNow}
                        />
                      }
                    />
                    {formik.values.isSentNow ? (
                      ''
                    ) : (
                      <>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MobileDateTimePicker
                            name="scheduledDateTime"
                            onChange={(e) => {
                              let stillUtc = moment.utc(e).toDate()
                              const date = moment(stillUtc)
                                .local()
                                .format('YYYY-MM-DDTHH:mm:ss')
                              setScheduledDateTime(date)
                              var b = dayjs(e).utc().format()
                              formik.setFieldValue('scheduledDateTime', b)
                            }}
                            value={scheduledDateTime}
                            label="Date and Time"
                            disabled={showEdit}
                            minDateTime={moment(
                              new Date().setDate(new Date().getDate())
                            )}
                            inputFormat="YYYY-MM-DD hh:mm a"
                            mask="____/__/__ __:__ _M"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        <div style={{ color: 'red', position: 'absolute' }}>
                          {!formik.values.scheduledDateTime &&
                            'At least One Delivery Schedule should be selected'}
                        </div>
                      </>
                    )}
                  </Box>
                )}
              </Grid>
              {/*<Grid container md={12} sm={12} xs={12}>*/}
              {/*  <Box className='stepper-input-label-margin'>*/}
              {/*  <div className='stepper-label-style'>*/}
              {/*    Recipients*/}
              {/*  </div>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
              <Grid container md={12} sm={12} xs={12}>
                <FormControl
                  disabled={showEdit}
                  error={companyLevel.filter((v) => v.isAssigned).length < 1}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <Box className="stepper-input-label-margin">
                      <div className="stepper-label-style">Company Level</div>

                      {companyLevel?.map((rol, index) => (
                        <>
                          <FormControlLabel
                            key={index}
                            label={rol?.name}
                            control={
                              <Checkbox
                                checked={rol?.isAssigned}
                                onChange={(event) =>
                                  handleCompanyPrivileges(event, index)
                                }
                              />
                            }
                          ></FormControlLabel>
                          <br />
                        </>
                      ))}

                      {companyLevel.filter((v) => v.isAssigned).length < 1 ? (
                        <FormHelperText>
                          One item should be selected
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </Box>
                  </FormGroup>
                </FormControl>
                <FormControl
                  disabled={showEdit}
                  error={contactType.filter((v) => v.isAssigned).length < 1}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <Box className="stepper-input-label-margin">
                      <div className="stepper-label-style">Contact Roles</div>
                      {contactType?.map((rol, index) => (
                        <>
                          <FormControlLabel
                            key={index}
                            label={rol?.name}
                            control={
                              <Checkbox
                                checked={rol?.isAssigned}
                                onChange={(event) =>
                                  handleContactPrivileges(event, index)
                                }
                              />
                            }
                          ></FormControlLabel>
                          <br />
                        </>
                      ))}
                      {contactType.filter((v) => v.isAssigned).length < 1 ? (
                        <FormHelperText>
                          One item should be selected
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </Box>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{ marginBottom: '10px' }}
              >
                <div className="stepper-label-style">Subject</div>
                <TextField
                  elevation={0}
                  id="subject"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  fullWidth
                  disabled={showEdit}
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{ marginBottom: '10px' }}
              >
                <div className="stepper-label-style">Body</div>
                <ReactQuill
                  theme="snow"
                  name="body"
                  readOnly={showEdit}
                  id="body"
                  modules={modules}
                  formats={formats}
                  onChange={(e) => formik.setFieldValue('body', e)}
                  value={formik.values.body}
                />
              </Grid>
              {/* <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{ marginBottom: '10px' }}
              >
                <TextField
                  type={'email'}
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  fullWidth
                  disabled={showEdit}
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Button
                          onClick={() => sendTestNotificationMail()}
                          disabled={
                            !(
                              formik.values.email &&
                              formik.values.body &&
                              formik.values.subject
                            )
                          }
                          endIcon={<SendIcon />}
                        >
                          Send mail
                        </Button>
                        Email:
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}

              {!showUpdate && !props?.detailsData ? (
                <Box
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '5px',
                    background: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className="stepper-input-label-margin"
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                      !(
                        (formik.values.isApp || formik.values.isEmail) &&
                        formik.values.subject &&
                        formik.values.body &&
                        !companyLevel.filter((v) => v.isAssigned).length < 1 &&
                        ((!formik.values.isSentNow &&
                          formik.values.scheduledDateTime) ||
                          formik.values.isSentNow) &&
                        !contactType.filter((v) => v.isAssigned).length < 1
                      )
                    }
                    onClick={() => saveDraft()}
                    style={{ margin: '0 5px' }}
                  >
                    Save Draft
                  </Button>
                  <Button
                    disabled={
                      !(
                        (formik.values.isApp || formik.values.isEmail) &&
                        formik.values.subject &&
                        formik.values.body &&
                        !companyLevel.filter((v) => v.isAssigned).length < 1 &&
                        ((!formik.values.isSentNow &&
                          formik.values.scheduledDateTime) ||
                          formik.values.isSentNow) &&
                        !contactType.filter((v) => v.isAssigned).length < 1
                      )
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ margin: '0 5px' }}
                  >
                    Submit
                  </Button>
                </Box>
              ) : (
                <>
                  {[1, 2].includes(props?.detailsData?.status) ? (
                    <Box
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      className="stepper-input-label-margin"
                    >
                      <Button
                        disabled={
                          !(
                            (formik.values.isApp ||
                              formik.values.isEmail ||
                              formik.dirty) &&
                            !companyLevel.filter((v) => v.isAssigned).length <
                            1 &&
                            !contactType.filter((v) => v.isAssigned).length < 1
                          ) ||
                          (!formik.values.isSentNow &&
                            !formik.values.scheduledDateTime)
                        }
                        type="button"
                        onClick={() => saveDraft('update')}
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Update Draft
                      </Button>
                      <Button
                        disabled={
                          !(
                            (formik.values.isApp || formik.values.isEmail) &&
                            !companyLevel.filter((v) => v.isAssigned).length <
                            1 &&
                            ((!formik.values.isSentNow &&
                              formik.values.scheduledDateTime) ||
                              formik.values.isSentNow) &&
                            !contactType.filter((v) => v.isAssigned).length < 1
                          )
                        }
                        type="button"
                        onClick={() => updateForm()}
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Submit
                      </Button>
                    </Box>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  )
}

export default NotificationCreate
