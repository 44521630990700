import { Box, Button, Grid, IconButton, Paper } from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import LoadingSection from '../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import { invoiceBillingDetailsDownload } from '../../../api/get/getApi'
import DownloadIcon from '@mui/icons-material/Download'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import ForwardIcon from '@mui/icons-material/Forward'
import { LoadingButton } from '@mui/lab'
import { getCompanyLevel, formatDate } from '../../../helpers/HelperFunctions'
import {
  FlexBox,
  SpaceBetweenBox,
} from '../../../common/styles/styled-components/StyledContainers'
import { TitleText } from '../../../common/styles/styled-components/StyledTypography'
import ThemeButton from '../../../common/components/button/Button'
import ErrorIcon from '@mui/icons-material/Error';
import { useToastContext } from '../../../App'
import { TOAST_MESSAGES } from '../../../helpers/Constants'

export function InvoiceDetails(props) {
  const { toast } = useToastContext()
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [details, setDetails] = React.useState()
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const getDetailsApi = () => {
    setLoader(true)
    invoiceBillingDetailsDownload(
      props.billId,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setLoader(false)
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let urlBlob = URL.createObjectURL(blob)

        setDetails(urlBlob)
      })
      .catch((error) => {
        toast.showToast(
          error?.response?.data?.message || TOAST_MESSAGES.ProblemInPdfBill,
          'error'
        )
        setLoader(false)
      })
  }
  const downLoadPdf = () => {
    var link = document.createElement('a')
    link.href = details
    link.download = 'bill-' + props.billId + '.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    getDetailsApi()
  }, [])

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Paper elevation={6}>
        {loader ? (
          <div
            style={{
              padding: '5px 10px 0 20px',
            }}
          >
            <SkeletonTableLoader></SkeletonTableLoader>
          </div>
        ) : (
          <Box>
            <div style={{ padding: '0 0 0 20px' }}>
              <br />
              <Grid
                container
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                rowSpacing={{ xs: 2, sm: 2, md: 3 }}
              >
                {/* row 1 */}
                <Grid item md={12} sm={12} xs={12}>
                  <SpaceBetweenBox>
                    <FlexBox gap={2}>
                      <ThemeButton
                        text="Go to List"
                        variant="text"
                        onClick={props.handleBack}
                        startIcon={
                          <ForwardIcon
                            style={{ transform: 'rotate(180deg)' }}
                          />
                        }
                      />
                      <TitleText>Details [ {props?.details.billId} ]</TitleText>
                    </FlexBox>
                    <LoadingButton
                      loadingPosition="start"
                      startIcon={<DownloadIcon />}
                      onClick={downLoadPdf}
                      variant="outlined"
                    >
                      DownLoad Bill
                    </LoadingButton>
                  </SpaceBetweenBox>
                </Grid>
                <Grid item md={2.5} sm={2.5} xs={2.5}>
                  <Paper elevation={6} sx={{}}>
                    <Box className="card-box">
                      <h4 style={{ margin: '10px 0' }}>Billing Date</h4>
                      {formatDate(new Date(props?.details?.createdDate))}
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={2.5} sm={2.5} xs={2.5}>
                  <Paper elevation={6} sx={{}}>
                    <Box className="card-box">
                      <h4 style={{ margin: '10px 0' }}>Billing Period</h4>
                      {formatDate(new Date(props?.details?.cycleDate))} -{' '}
                      {formatDate(new Date(props?.details?.dueDate))}
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                  <Paper elevation={6} sx={{}}>
                    <Box className="card-box">
                      <h4 style={{ margin: '10px 0' }}>Status</h4>

                      {new Date(props.details?.dueDate) < new Date() ? (
                          <span style={{ display: 'flex' }}>
                            <ErrorIcon color="error" /> Overdue
                          </span>
                        ) : props?.details?.isLive && props.details.readyForPdfFlag ? (
                          <span style={{ display: 'flex' }}>
                            <TaskAltIcon color="success" /> Paid
                          </span>
                        ) : !props.details.readyForPdfFlag && (
                          <span style={{ display: 'flex' }}>
                            <UnpublishedIcon color="warning" /> UnPaid
                          </span>
                        ) }
                    </Box>
                  </Paper>
                </Grid>

                {details && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Paper elevation={6}>
                      <div style={{ textAlign: 'left' }}>
                        <iframe
                          src={`${details}#toolbar=0&navpanes=0&scrollbar=0`}
                          style={{
                            width: '100%',
                            height: '500px',
                            background: '#FFFFFF',
                          }}
                          allowFullScreen
                        ></iframe>
                      </div>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </div>

            <br />
          </Box>
        )}
      </Paper>
    </>
  )
}

export default InvoiceDetails
