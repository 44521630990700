import { useEffect, useState } from 'react'

import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from '@mui/material'

import './signup.css'

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import MailIcon from '@mui/icons-material/Mail'
import logo from '../../../assets/Altigen_logo_with_text.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { checkSubdomain, companySignup } from '../../../api/post/postApi'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp'
// email validator
import validator from 'validator'
import LoadingSection from '../../../common/components/loaders/loader'
import { requiredLabel, saveToken } from '../../../helpers/HelperFunctions'
import { COMPANY_LEVELS, COMPANY_STATUS } from '../../../helpers/Constants'
import Carousel from '../../../common/components/carousel/Carousel'
import { useToastContext } from '../../../App'

function Signup(props) {
  const { toast } = useToastContext()
  const navigate = useNavigate()
  useEffect(() => {
    findcompany()
  }, [])

  const [parentId, setparentId] = useState('')
  const [domain, setdomain] = useState('')
  const [companyLevel, setcompanyLevel] = useState('')
  const [companyid, setcompanyid] = useState('')
  const [companyName, setcompanyName] = useState('')
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowConfirmPassword, setShowConfirmPassword] = useState(false)

  const findcompany = async () => {
    setPageLoader(true)
    const getfullurl = window.location.href
    const url = {
      fullUrl: getfullurl,
      // fullUrl: 'https://newsp2.sdcloud.dev/signup'
    }
    try {
      const data = await checkSubdomain(url)
      if (data) {
        setPageLoader(false)
        setcompanyName(data.data.result.companyName)
        localStorage.setItem('companyname', data.data.result.companyName)
        setcompanyLevel(data.data.result.companyLevel)
        setparentId(data.data.result.parentId)
        setcompanyid(data.data.result.companyId)
        setdomain(data.data.result.subDomain)
        switch (data.data.result.COMPANY_STATUS) {
          case COMPANY_STATUS.Pending:
            return null
          case COMPANY_STATUS.Active:
            return navigate('/login')
          case COMPANY_STATUS.Draft:
            return navigate('/login')
          case COMPANY_STATUS.Rejected:
            return navigate('/login')
          case COMPANY_STATUS.ApprovalPending:
            return navigate('/login')
        }
      }
    } catch (error) {
      setPageLoader(false)
      navigate('/login')
    }
  }
  // preloader hooks
  const [loader, setloader] = useState(false)
  const [pageLoader, setPageLoader] = useState(false)
  const [searchParams] = useSearchParams();

  // sign up hooks
  const [signup, setsignup] = useState({
    companyname: '',
    email: searchParams.get('email') || '',
    password: '',
  })

  const signuphandler = (e) => {
    const data = { ...signup }
    data[e.target.id] = e.target.value
    setsignup(data)
  }

  const [password, setpassword] = useState('')

  const [emailhelpertext, setemailhelpertext] = useState('')
  const [companyhelpertext, setcompanyhelpertext] = useState('')
  const [passwordhelpertext, setpasswordhelpertext] = useState('')
  const [emptypasswordhelpertext, setemptypasswordhelpertext] = useState('')
  const [emptyconfirmpasswordhelpertext, setemptyconfirmpasswordhelpertext] =
    useState('')

  // create account function
  const createAccount = () => {
    if (validator.isEmail(signup.email)) {
      setemailhelpertext('')
    } else {
      setemailhelpertext('please enter valid email')
    }
    if (companyName == '') {
      setcompanyhelpertext('please enter your company name')
    } else {
      setcompanyhelpertext('')
    }
    if (password == '') {
      setemptypasswordhelpertext('Please enter password')
    } else {
      if (
        !/^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(password)
      ) {
        setemptypasswordhelpertext(
          'Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character'
        )
      } else {
        setemptypasswordhelpertext('')
      }
    }
    if (signup.password == '') {
      setemptyconfirmpasswordhelpertext('confirm password should not be empty')
    } else {
      if (
        !/^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
          signup.password
        )
      ) {
        setemptyconfirmpasswordhelpertext(
          'Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character'
        )
      } else {
        if (password !== signup.password) {
          setemptyconfirmpasswordhelpertext('password should be same')
        } else {
          setemptyconfirmpasswordhelpertext('')
        }
      }
    }
    if (
      validator.isEmail(signup.email) &&
      password == signup.password &&
      signup.password != '' &&
      companyName != '' &&
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(password) &&
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
        signup.password
      )
    ) {
      proceedSignup()
    }
  }

  const proceedSignup = async () => {
    setloader(true)
    const data = {
      parentId: parentId,
      companyId: companyid,
      email: signup.email,
      password: signup.password,
      // companyName:signup.companyname,
      companyName: companyName,
      domain: domain,
      companyLevel: companyLevel,
    }
    try {
      const res = await companySignup(data)
      if (res) {
        localStorage.setItem('email', data.email)
        setloader(false)
        const datamodal = res.data.result.companyInfo
        if (datamodal.companyStatus == COMPANY_STATUS.Draft) {
          saveToken(res.data.result.token)
          localStorage.setItem('companyid', datamodal.companyId)
          navigate('/signup-stepper')
        } else {
          null
        }
      }
    } catch (error) {
      setloader(false)
      toast.showToast(error?.response?.data?.message, 'error')
    }
  }

  const [passwordtype, setpasswordtype] = useState(false)

  const handlePasswordShow = () => {
    setShowPassword(!isShowPassword)
  }
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!isShowConfirmPassword)
  }

  return (
    <div>
      <Grid container>
        {/* spliting in to 2 halfs */}
        {pageLoader ? (
          <LoadingSection />
        ) : (
          <>
            <Grid item md={7} sm={7} xs={12}>
              <Box
                sx={{
                  borderRadius: '20px',
                  margin: '15px',
                  backgroundColor: '#0984E3',
                  height: '96vh',
                  width: '80%',
                }}
              >
                <Carousel />
              </Box>
            </Grid>
            <Grid item md={5} sm={5} xs={12}>
              <Box className="Main-container">
                <Box className="Reseller-signup-main-form-container">
                  <Box className="Rsmf-container1">
                    <img
                      src={logo}
                      width="250px"
                      alt="logo"
                      style={{ objectFit: 'cover' }}
                    />
                  </Box>

                  <Box className="Rsmf-container2">
                    <span>
                      Sign Up For a
                      <br />
                      {companyLevel == COMPANY_LEVELS.serviceprovider
                        ? 'Service Provider'
                        : ''}
                      {companyLevel == COMPANY_LEVELS.reseller
                        ? 'Reseller'
                        : ''}
                      {companyLevel == COMPANY_LEVELS.customer
                        ? 'Customer'
                        : ''}
                    </span>
                  </Box>

                  <Box className="Rsmf-container3">
                    {requiredLabel('Company Name')}
                    <TextField
                      helperText={companyhelpertext}
                      error={companyhelpertext != '' ? true : false}
                      id="companyname"
                      value={companyName}
                      disabled={true}
                      onChange={(e) => setcompanyName(e.target.value)}
                      // onChange={(e) => signuphandler(e)}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        // readOnly: true,
                        style: {
                          height: '30px',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <AlternateEmailIcon className="textfield-iconStyle" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {requiredLabel('Email')}
                    <TextField
                      helperText={emailhelpertext}
                      error={emailhelpertext != '' ? true : false}
                      id="email"
                      onChange={(e) => signuphandler(e)}
                      variant="outlined"
                      size="small"
                      value={signup.email}
                      disabled={searchParams.get('email')}
                      InputProps={{
                        style: {
                          height: '30px',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <MailIcon className="textfield-iconStyle" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {requiredLabel('Password')}
                    <TextField
                      helperText={emptypasswordhelpertext}
                      error={emptypasswordhelpertext != '' ? true : false}
                      onChange={(e) => setpassword(e.target.value)}
                      type={isShowPassword ? 'text' : 'password'}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        style: {
                          height: '30px',
                        },
                        endAdornment: (
                          <InputAdornment
                            className="cursorStyle_pointer"
                            onClick={handlePasswordShow}
                            position="end"
                          >
                            {isShowPassword ? (
                              <VisibilitySharpIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {requiredLabel('Confirm Password')}
                    <TextField
                      helperText={
                        emptyconfirmpasswordhelpertext
                          ? emptyconfirmpasswordhelpertext
                          : passwordhelpertext
                            ? passwordhelpertext
                            : null
                      }
                      error={
                        emptyconfirmpasswordhelpertext != ''
                          ? true
                          : passwordhelpertext != ''
                            ? true
                            : false
                      }
                      onChange={(e) => signuphandler(e)}
                      id="password"
                      variant="outlined"
                      size="small"
                      type={isShowConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        style: {
                          height: '30px',
                        },
                        endAdornment: (
                          <InputAdornment
                            className="cursorStyle_pointer"
                            onClick={handleShowConfirmPassword}
                            position="end"
                          >
                            {isShowConfirmPassword ? (
                              <VisibilitySharpIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className="Rsmf-container4">
                    <Button
                      variant="contained"
                      disableFocusRipple
                      fullWidth
                      sx={{
                        height: '55px',
                        borderRadius: '8px',
                        //fontSize: '14px',
                        letterSpacing: '1.5px',
                      }}
                      size="large"
                      onClick={createAccount}
                    >
                      create account &emsp;{' '}
                      {loader ? (
                        <CircularProgress size={20} sx={{ color: '#fff' }} />
                      ) : null}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default Signup
