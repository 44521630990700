import Box from '@mui/material/Box'
import { Button, Grid } from '@mui/material'
import * as React from 'react'
import { Form, Formik } from 'formik'
import jwt_decode from 'jwt-decode'
import { useLocation, useSearchParams } from 'react-router-dom'
import LoadingSection from '../../../common/components/loaders/loader'
import { paymentCardPostApi } from '../../../api/payment/paymentAPI'
import ReusableTextfield from '../../../common/components/textField/textField'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import { BillingFormValidation } from '../../../validations/BillingValidation'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

const initialValues = {
  firstName: '',
  lastName: '',
  cardNumber: '',
  expirationMonth: '',
  expirationYear: '',
  cvv: '',
}

function AddNewPaymentCard(props) {
  const { toast } = useToastContext()
  const { dispatch, state: commonState } = useCommonRedux()
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const onChangeRole = (e) => {
    props?.toggleDrawer('right', false)
  }
  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Box role="presentation">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            padding: '5px 10px 0 20px',
          }}
        >
          <Formik
            initialValues={{
              ...initialValues,
            }}
            validationSchema={BillingFormValidation}
            onSubmit={(data) => {
              setSubmitLoader(true)
              const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
              const decoded = jwt_decode(decodeToken)
              let companyId = searchParams.get('company_id')
              if (!companyId) {
                companyId = decoded?.company_id
              }
              data.companyId = companyId
              paymentCardPostApi(
                data,
                getCompanyLevel(
                  searchParams.get('company_id'),
                  location.pathname.slice(1)
                )
              )
                .then((response) => {
                  props.toggleDrawer('right', false, '', '', true, response)
                  setSubmitLoader(false)
                  toast.showToast(response.data.message)
                })
                .catch((error) => {
                  setSubmitLoader(false)
                  toast.showToast(error.response.data.message, 'error')
                })
            }}
          >
            <Form>
              <Grid
                container
                columnSpacing={4}
                rowSpacing={3}
                style={{ marginBottom: '10px' }}
              >
                <Grid item md={6} sm={6} xs={6}>
                  <ReusableTextfield
                    id="First Name"
                    name="firstName"
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    required
                    FormHelperTextProps={{ style: { fontSize: '12px' } }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <ReusableTextfield
                    id="Last Name"
                    name="lastName"
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    required
                    FormHelperTextProps={{ style: { fontSize: '12px' } }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <ReusableTextfield
                    id="Card Number"
                    name="cardNumber"
                    placeholder="**************"
                    type="number"
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    required
                    FormHelperTextProps={{ style: { fontSize: '12px' } }}
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                  <ReusableTextfield
                    id="Expiration Month"
                    name="expirationMonth"
                    required
                    placeholder="MM"
                    type="number"
                    fullWidth
                    inputProps={{
                      minLength: 0,
                      maxLength: 2,
                    }}
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    FormHelperTextProps={{ style: { fontSize: '12px' } }}
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                  <ReusableTextfield
                    id="Expiration Year"
                    name="expirationYear"
                    placeholder="YYYY"
                    required
                    fullWidth
                    inputProps={{
                      minLength: 4,
                      maxLength: 4,
                    }}
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    FormHelperTextProps={{ style: { fontSize: '12px' } }}
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                  <ReusableTextfield
                    id="CVV"
                    name="cvv"
                    type="number"
                    fullWidth
                    required
                    inputProps={{
                      minLength: 3,
                      maxLength: 4,
                    }}
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    FormHelperTextProps={{ style: { fontSize: '12px' } }}
                  />
                </Grid>
              </Grid>
              {checkPermission(
                [
                  PERMISSIONS.masterBillingCreate,
                  PERMISSIONS.serviceproviderBillingCreate,
                  PERMISSIONS.resellerBillingCreate,
                  PERMISSIONS.customerBillingCreate,
                ],
                commonState.currentCompanyDetails
              ) ? (
                <Box
                  style={{
                    position: 'fixed',
                    marginTop: '10px',
                    background: '#fff',
                  }}
                  className="stepper-payemnt-buttoncontainer"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                  >
                    Add New Payment
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </Form>
          </Formik>
        </div>
      </Box>
    </>
  )
}

export default AddNewPaymentCard
