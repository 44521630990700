import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import { Chip, Grid, List, Stack } from '@mui/material'
import * as React from 'react'
import { masterUserDetailApi } from '../../../api/master/masterAPI'
import { useEffect } from 'react'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

export default function ViewUser(props) {
  const { dispatch, state: commonState } = useCommonRedux();
  const [userDetails, setUserDetails] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const getUserDetailsApi = (id) => {
    if (id) {
      setLoader(true)
      masterUserDetailApi(
        id,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          let detailsResponse = response.data.result
          if (detailsResponse.roles) {
            const sortedData = [...detailsResponse.roles].sort((a, b) =>
              a.roleName.localeCompare(b.roleName)
            )
            detailsResponse.roles = sortedData
          }
          setUserDetails(detailsResponse)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }
  useEffect(() => {
    getUserDetailsApi(props?.userId?.id)
  }, [props?.userId?.id])
  return (
    <Box role="presentation">
      {loader ? (
        <div
          style={{
            padding: '5px 10px 0 20px',
          }}
        >
          <SkeletonTableLoader></SkeletonTableLoader>
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              borderBottom: '1px solid silver',
              justifyContent: 'space-between',
              padding: '5px 10px 0 20px',
            }}
          >
            <h2 style={{ fontSize: '14px' }}>
              <div
                style={{ display: 'flex', position: 'absolute', right: '70px' }}
              >
                {checkPermission(
                  [
                    PERMISSIONS.masterUserUpdate,
                    PERMISSIONS.serviceproviderUserUpdate,
                    PERMISSIONS.resellerUserUpdate,
                    PERMISSIONS.customerUserUpdate,
                  ],
                  commonState.currentCompanyDetails
                ) ? (
                  <div
                    onClick={() => props?.gotoDrawer('editUser', userDetails)}
                    style={{
                      margin: '0px -10px',
                      fontSize: '15px',
                      color: ' #000000a1',
                      cursor: 'pointer',
                    }}
                  >
                    <EditIcon
                      style={{
                        fontSize: '12px',
                        verticalAlign: 'middle',
                        margin: '0 5px 0 15px',
                      }}
                    />
                    Edit User
                    {/*<EditIcon  style={{fontSize:'12px',verticalAlign:'middle', margin: '0 5px 0 15px'}}></EditIcon>*/}
                    {/*Edit Roles*/}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <label style={{ fontWeight: 'normal' }}>First Name:</label>
                </Grid>
                <Grid item xs={6}>
                  <label>{userDetails.firstName} </label>
                </Grid>
                <Grid item xs={4}>
                  <label style={{ fontWeight: 'normal' }}>Last Name:</label>
                </Grid>
                <Grid item xs={6}>
                  <label> {userDetails.lastName}</label>
                </Grid>
                <Grid item xs={4}>
                  <label style={{ fontWeight: 'normal' }}>Email:</label>
                </Grid>
                <Grid item xs={6}>
                  <label>{userDetails.email}</label>
                </Grid>
                <Grid item xs={4}>
                  <label style={{ fontWeight: 'normal' }}>Phone Number:</label>
                </Grid>
                <Grid item xs={6}>
                  <label>{userDetails?.phone}</label>
                </Grid>
              </Grid>
            </h2>
          </div>
          <List>
            {/*// <FormGroup>*/}
            {/*//     <FormControlLabel control={<Checkbox defaultChecked />} label={text} />*/}
            {/*// </FormGroup>*/}
            <div style={{ padding: '0px 20px' }}>
              <h2>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>Manage Roles</div>
                  {checkPermission(
                    [
                      PERMISSIONS.masterUserUpdate,
                      PERMISSIONS.serviceproviderUserUpdate,
                      PERMISSIONS.resellerUserUpdate,
                      PERMISSIONS.customerUserUpdate,
                    ],
                    commonState.currentCompanyDetails
                  ) ? (
                    <div
                      onClick={() => props?.gotoDrawer('editRole', userDetails)}
                      style={{
                        padding: '0px 40px',
                        fontSize: '15px',
                        color: ' #000000a1',
                        cursor: 'pointer',
                      }}
                    >
                      <EditIcon
                        style={{
                          fontSize: '12px',
                          verticalAlign: 'middle',
                          margin: '0 5px 0 15px',
                        }}
                      />
                      Edit Role
                      {/*<EditIcon  style={{fontSize:'12px',verticalAlign:'middle', margin: '0 5px 0 15px'}}></EditIcon>*/}
                      {/*Edit Roles*/}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </h2>
              <Stack direction="row" spacing={1}>
                {userDetails?.roles?.map((text, index) => (
                  <Chip label={text.roleName} variant="outlined" />
                ))}
              </Stack>
            </div>
          </List>
        </>
      )}
    </Box>
  )
}
