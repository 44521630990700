import { createRef, useEffect, useState } from 'react'
import {
    Box,
    CircularProgress,
    Container,
    IconButton,
    InputBase,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import SearchIcon from '@mui/icons-material/Search'
import {
    SERVICE_NAMES,
    SERVICES,
    TOAST_MESSAGES,
} from '../../../helpers/Constants'
import { getMaxUCOrderIconStatus } from '../../../helpers/HelperFunctions'
import ThemeButton from '../../../common/components/button/Button'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { getServiceCancellations } from '../../../api/get/getApi'
import { useSearchParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { useToastContext } from '../../../App'
import { approvalServiceCancellation } from '../../../api/put/putApi'
import ConfirmationModal from '../../../common/components/custom/modal/modal'

function ServiceCancellationApprovals() {
    const [loader, setLoader] = useState(false)
    const [services, setServices] = useState([])
    const [actualServices, setActualServices] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const { toast } = useToastContext()
    const [searchParams] = useSearchParams()
    const pageTopRef = createRef()
    const [approvingOrderIds, setApprovingOrderIds] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)

    const inputHandler = (e) => {
        const searchText = e.target.value.toLowerCase()
        const filteredData = actualServices.filter(
            (service) =>
                searchText === '' ||
                service.companyName.toLowerCase().includes(searchText)
        )
        setServices(filteredData)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        pageTopRef.current.scrollTop = 0
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value))
        setPage(0)
        pageTopRef.current.scrollTop = 0
    }

    const getCompanyId = () => {
        const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
        const decoded = jwt_decode(decodeToken)
        if (decoded) {
            let companyId = searchParams.get('company_id')
            if (!companyId) {
                companyId = decoded?.company_id
            }
            return companyId
        }
    }

    const handleOpenConfirmation = (selectedService) => {
        setSelectedRow(selectedService)
        setIsOpenConfirmation(!isOpenConfirmation)
    }
    const handleClose = () => setIsOpenConfirmation(false)

    const loadServices = (companyId) => {
        setLoader(true)
        getServiceCancellations(companyId)
            .then((res) => {
                const result = res.data.result
                setServices(result)
                setActualServices(result)
            })
            .catch((error) => {
                toast.showToast(error.response.data.message, 'error')
            })
            .finally(() => setLoader(false))
    }

    useEffect(() => {
        loadServices(getCompanyId())
    }, [])

    const handleApproveCancellation = () => {
        const payload = {
            companyId: getCompanyId(),
            service: selectedRow.service,
            orderId: selectedRow.orderId,
            isApproved: true,
        }
        setApprovingOrderIds((prevData) => [...prevData, selectedRow.orderId])
        approvalServiceCancellation(payload)
            .then(() => toast.showToast(TOAST_MESSAGES.CancellationApproved))
            .catch((error) =>
                toast.showToast(
                    error?.response?.data?.message || TOAST_MESSAGES.SomethingWentWrong,
                    'error'
                )
            )
            .finally(() =>
                setApprovingOrderIds((prevData) =>
                    prevData.filter((id) => id !== selectedRow.orderId)
                )
            )
    }
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - services.length) : 0
    return (
        <>
            <Container maxWidth="xl">
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '15px 0',
                    }}
                >
                    <Paper
                        component="div"
                        sx={{
                            p: '2px 4px',
                            m: '10px 0',
                            display: 'flex',
                            alignItems: 'center',
                            width: 400,
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={inputHandler}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>
                <TableContainer
                    sx={{
                        height:
                            rowsPerPage == 5 || services.length <= 7
                                ? 'max-content'
                                : 'calc(95vh - 200px)',
                    }}
                    component={Paper}
                    ref={pageTopRef}
                >
                    <Table
                        stickyHeader
                        sx={{ minWidth: 500 }}
                        aria-label="custom pagination table"
                    >
                        <TableHead className="thead">
                            <TableRow>
                                <TableCell>S.No.</TableCell>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Service Name</TableCell>
                                <TableCell>Expiration Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {loader ? (
                            <TableBody className="tbody">
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6}>
                                        <SkeletonTableLoader></SkeletonTableLoader>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody className="tbody">
                                {(rowsPerPage > 0
                                    ? services.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    : services
                                ).map((row, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell width={'10px'}>
                                            {page * rowsPerPage + (index + 1)}
                                        </TableCell>
                                        <TableCell>{row.companyName}</TableCell>
                                        <TableCell>{SERVICE_NAMES[row.service]}</TableCell>
                                        <TableCell>
                                            {row.expirationDate
                                                ? moment(row.expirationDate).format('DD-MM-YYYY')
                                                : '-'}
                                        </TableCell>
                                        <TableCell>{getMaxUCOrderIconStatus(row.status)}</TableCell>
                                        <TableCell
                                            style={{
                                                display: 'flex',
                                                gap: '10px',
                                                maxWidth: 'fit-content',
                                            }}
                                        >
                                            <ThemeButton
                                                text={'Approve'}
                                                variant="contained"
                                                size="medium"
                                                style={{ height: '32px' }}
                                                startIcon={<TaskAltIcon />}
                                                disabled={approvingOrderIds.includes(row.orderId)}
                                                endIcon={
                                                    approvingOrderIds.includes(row.orderId) && (
                                                        <CircularProgress
                                                            size={20}
                                                            sx={{ color: '#fff' }}
                                                        />
                                                    )
                                                }
                                                onClick={() => handleOpenConfirmation(row)}
                                            ></ThemeButton>
                                            <Tooltip title="Rejection will be available at Phase 2">
                                                <ThemeButton
                                                    text={'Reject'}
                                                    variant="outlined"
                                                    size="medium"
                                                    style={{ height: '32px' }}
                                                    startIcon={<CancelOutlinedIcon />}
                                                    disabled={approvingOrderIds.includes(row.orderId)}
                                                ></ThemeButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={services.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>
            <ConfirmationModal
                title="Approve Service Cancellation"
                content={`Are you sure want to approve the cancellation of ${SERVICE_NAMES[selectedRow.service]} for ${selectedRow.companyName} ?`}
                isOpen={isOpenConfirmation}
                handleConfirm={handleApproveCancellation}
                handleClose={handleClose}
                btnOk="Approve Cancellation"
                btnCancel="Cancel"
            />
        </>
    )
}

export default ServiceCancellationApprovals
