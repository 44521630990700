import { Box, Drawer, Grid, Tab, Tooltip, Zoom } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import * as signalR from '@microsoft/signalr'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import EngineeringIcon from '@mui/icons-material/Engineering'
import {
  getCompanyProfile,
  getPermissionList,
} from '../../../../api/get/getApi'
import { useToastContext } from '../../../../App'
import {
  setCurrentCompanyDetails,
  setPermissionList,
} from '../../../../store/slice/commonSlice'
import {
  checkPermission,
  executeWithTryCatch,
  gettingCompanyId,
} from '../../../../helpers/HelperFunctions'
import {
  COMPANY_LEVELS,
  ENV_URLS,
  PERMISSIONS,
  TOAST_MESSAGES,
} from '../../../../helpers/Constants'
import Home from '../../../../pages/home/Home'
import Billing from '../../../../pages/billing/Billing'
import Settings from '../../../../pages/settings/Settings'
import { setNotifications } from '../../../../store/slice/settingsSlice'
import UserManagement from '../../../../pages/userManagement/UserManagement'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import HailIcon from '@mui/icons-material/Hail'
import SalesRep from '../../../../pages/salesRep/SalesRep'
import SkeletonTableLoader from '../../loaders/skeletonLoader'
import LoadingSection from '../../loaders/loader'

const drawerWidth = 70
const dashboardPermission = [
  PERMISSIONS.masterDashboard,
  PERMISSIONS.serviceproviderDashboard,
  PERMISSIONS.resellerDashboard,
  PERMISSIONS.customerDashboard,
]
const userRolePermission = [
  PERMISSIONS.masterUserView,
  PERMISSIONS.masterRoleView,
  PERMISSIONS.serviceproviderUserView,
  PERMISSIONS.serviceproviderRoleView,
  PERMISSIONS.resellerUserView,
  PERMISSIONS.resellerRoleView,
  PERMISSIONS.customerUserView,
  PERMISSIONS.customerRoleView,
]
const servicePermission = [
  PERMISSIONS.masterProductView,
  PERMISSIONS.serviceproviderProductView,
  PERMISSIONS.resellerProductView,
  PERMISSIONS.customerProductView,
]
const billingPermission = [
  // PERMISSIONS.masterBillingView, //hide billing for master
  PERMISSIONS.serviceproviderBillingView,
  PERMISSIONS.resellerBillingView,
  PERMISSIONS.customerBillingView,
]
const settingPermission = [
  PERMISSIONS.masterSettingView,
  PERMISSIONS.serviceproviderSettingView,
  PERMISSIONS.resellerSettingView,
  PERMISSIONS.customerSettingView,
  PERMISSIONS.serviceproviderCompanyView,
  PERMISSIONS.resellerCompanyView,
  PERMISSIONS.customerCompanyView,
  PERMISSIONS.masterBillingView,
  PERMISSIONS.serviceproviderBillingView,
  PERMISSIONS.resellerBillingView,
  PERMISSIONS.customerBillingView,
]

const salesRepPermission = [PERMISSIONS.salesRepServiceCancelApproval]

const sideMenu = [
  {
    label: 'Home',
    icon: (
      <HomeRoundedIcon
        className="sidebar-icons-style"
        sx={{ fontSize: '2rem' }}
      />
    ),
    value: '1',
    permission: dashboardPermission,
    customerLevel: '',
    isRevParent: false,
    isAltigenBilling: false,
  },
  {
    label: 'User & Role Management',
    icon: (
      <EngineeringIcon
        className="sidebar-icons-style"
        sx={{ fontSize: '2rem' }}
      />
    ),
    value: '2',
    permission: userRolePermission,
    customerLevel: '',
    isRevParent: false,
    isAltigenBilling: false,
  },
  {
    label: 'Billing',
    icon: (
      <AttachMoneyIcon
        className="sidebar-icons-style"
        sx={{ fontSize: '2rem' }}
      />
    ),
    value: '3',
    permission: billingPermission,
    customerLevel: '',
    isRevParent: true,
    isAltigenBilling: true,
  },
  {
    label: 'Settings',
    icon: (
      <SettingsIcon className="sidebar-icons-style" sx={{ fontSize: '2rem' }} />
    ),
    value: '4',
    permission: settingPermission,
    customerLevel: '',
    isRevParent: false,
    isAltigenBilling: false,
  },
  {
    label: 'Sales Rep',
    icon: (
      <HailIcon className="sidebar-icons-style" sx={{ fontSize: '2rem' }} />
    ),
    value: '5',
    permission: salesRepPermission,
    customerLevel: '',
    isRevParent: false,
    isAltigenBilling: false,
  },
]

const SideNavBar = () => {
  const { dispatch, state: commonState } = useCommonRedux()
  const { toast } = useToastContext()
  var localToken = localStorage.getItem('sd_cp.jwt:tkn')
  const [tabValue, setValue] = React.useState('1')
  const [detailsLoading, setDetailsLoading] = useState(false)
  let connection = ''

  useEffect(() => {
    getPermissionsList()
    if (!Object.keys(commonState.currentCompanyDetails || {})?.length) {
      executeWithTryCatch(getCompanyDetails, handleError)
    }
  }, [])

  useEffect(() => {
    if (localToken) {
      const options = {
        accessTokenFactory: () => {
          return localToken
        },
      }
      connection = new signalR.HubConnectionBuilder()
        .withUrl(`${ENV_URLS.URL}/usernotificationhub`, options)
        .withAutomaticReconnect()
        // .configureLogging(signalR.LogLevel.Information)
        .build()
      connection.on('usernotification', (data) => {
        if (data) {
          data.isViewed = false
          dispatch(setNotifications(data))
        }
      })
      connection.start().then((data) => {
        // connection.invoke('SendSync', 'Hello')
      })
    }
  }, [connection])

  const getCompanyDetails = async () => {
    setDetailsLoading(true)
    const response = await getCompanyProfile(
      location.pathname?.slice(1)?.toLowerCase(),
      gettingCompanyId()
    )
    dispatch(setCurrentCompanyDetails(response.data.result))
    setValue(
      response.data.result.companyLevel === COMPANY_LEVELS.salesrep ? '5' : '1'
    )
    setDetailsLoading(false)
  }

  const handleError = (error) => {
    toast.showToast(
      error.response.data?.message || TOAST_MESSAGES.UnableToFetchUserData,
      'error'
    )
    setDetailsLoading(false)
  }

  const getPermissionsList = async () => {
    try {
      const response = await getPermissionList()
      const permissionPrivileges = response.data.result.map(
        ({ privilege }) => privilege
      )
      dispatch(setPermissionList(permissionPrivileges))
      localStorage.setItem(
        'permissionList',
        JSON.stringify(permissionPrivileges)
      )
    } catch (error) {
      toast.showToast(error.response.data.message, 'error')
    }
  }

  const handleChange = (event, newValue) => {
    const tab = { navTab: newValue, tab: '1', innerTab: '1' }
    Object.entries(tab).forEach(([key, value]) => {
      sessionStorage.setItem(key, value)
    })
    setValue(newValue)
  }

  if (detailsLoading) return <LoadingSection />
  if (!commonState.currentCompanyDetails.id) return
  return (
    <>
      {/* {commonState.isSettingsOpen ? ( */}
      <Grid>
        <TabContext value={tabValue}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
              border: 'none',
              //display: 'none',
            }}
            variant="permanent"
            anchor="left"
          >
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                overflow: 'hidden',
              }}
            >
              <Box className="usermanagement-side-container-icons-container">
                <Box>
                  <TabList
                    TabIndicatorProps={{
                      style: { background: '#fff', width: '14px', left: 0 },
                    }}
                    orientation="vertical"
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                  >
                    {/* OLD SIDEMENU LOGICS */}
                    {sideMenu.map((mapDat) => {
                      if (
                        mapDat.value ==
                          (commonState.currentCompanyDetails.companyLevel ==
                          COMPANY_LEVELS.salesrep
                            ? 5
                            : 1) ||
                        (checkPermission(
                          mapDat.permission,
                          commonState.currentCompanyDetails
                        ) &&
                          (mapDat.value == 2 || mapDat.value == 4) &&
                          !mapDat.customerLevel &&
                          !mapDat.isRevParent) ||
                        // ||
                        // (checkPermission(mapDat.permission, commonState.currentCompanyDetails) && customerLevel == mapDat.customerLevel && mapDat.value== 3)
                        (checkPermission(
                          mapDat.permission,
                          commonState.currentCompanyDetails
                        ) &&
                          commonState.currentCompanyDetails?.isRevParent ==
                            mapDat.isRevParent &&
                          commonState.currentCompanyDetails?.isAltigenBilling ==
                            mapDat.isAltigenBilling &&
                          mapDat.value == 3)
                      ) {
                        return (
                          <Tab
                            key={mapDat.label}
                            label={
                              <Tooltip
                                TransitionComponent={Zoom}
                                title={mapDat.label}
                                placement="right-end"
                              >
                                <Box className="tabs_icons_style_withborder">
                                  <div className="left_side_icon"></div>
                                  <div className="right_side_icon">
                                    {mapDat.icon}
                                  </div>
                                </Box>
                              </Tooltip>
                            }
                            value={mapDat.value}
                          />
                        )
                      }
                    })}
                  </TabList>
                </Box>
              </Box>
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1 }}>
            <TabPanel value="1" sx={{ paddingLeft: '100px' }}>
              <Home />
            </TabPanel>
            <TabPanel value="2" sx={{ paddingLeft: '100px' }}>
              <UserManagement />
            </TabPanel>
            {commonState.currentCompanyDetails?.isRevParent && (
              <TabPanel value="3" sx={{ paddingLeft: '100px' }}>
                <Billing />
              </TabPanel>
            ) }
            <TabPanel value="4" sx={{ paddingLeft: '100px' }}>
              <Settings />
            </TabPanel>
            <TabPanel value="5" sx={{ paddingLeft: '100px' }}>
              <SalesRep />
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
    </>
  )
}

export default SideNavBar
