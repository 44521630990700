import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import { Container, Drawer, InputBase, Link, Switch, TableHead, Tooltip } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import SearchIcon from '@mui/icons-material/Search'
import AddNewRole from './addNewRole'
import ViewRoles from './viewRole'
import { useEffect, useState } from 'react'
import EditRole from './editRole'
import {
  masterRoleDeleteApi,
  masterRoleListApi,
} from '../../../api/master/masterAPI'
import jwt_decode from 'jwt-decode'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import { useLocation, useSearchParams } from 'react-router-dom'
import LoadingSection from '../../../common/components/loaders/loader'
import ConfirmationModal from '../../../common/components/custom/modal/modal'
import NoDataFoundCard from '../../../common/components/cards/noDataFoundCard'
import { createRef } from 'react'
import { setRolesList } from '../../../store/slice/userManagementSlice'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import { styled } from '@mui/system'
import { Add as AddIcon } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel'
import DrawerHeader from '../../../common/components/drawer/DrawerHeader'
import ThemeButton from '../../../common/components/button/Button'
import { useUserManagementRedux } from '../UserManagement'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

function RoleTable(props) {
  const { state: commonState } = useCommonRedux();
  const { dispatch, state: _state } = useUserManagementRedux()
  const { toast } = useToastContext();
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [rowsList, setRowsList] = React.useState([])
  const [edited, setEdited] = React.useState('')
  const [details, setDetails] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - rowsList?.length)
      : 0
  const pageTopRef = createRef()
  const [isOpen, setIsOpen] = useState(false)
  let inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase()
    const filteredData = _state.rolesList.filter((el) => {
      if (lowerCase === '') {
        return el
      } else {
        return el?.roleName.toLowerCase().includes(lowerCase)
      }
    })
    setRowsList(filteredData)
    setPage(0)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleDelete = (id) => {
    setSubmitLoader(true)
    masterRoleDeleteApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setSubmitLoader(false)
        toast.showToast(response.data.message, 'success')
        getDecodeValue()
        handleClose()
        toggleDrawer('right', false)
      })
      .catch((error) => {
        toast.showToast(error.response.data.message, 'error')
        setSubmitLoader(false)
      })
  }
  const getRoleList = (id) => {
    setLoader(true)
    masterRoleListApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setLoader(false)
        const sortedData = [...response.data.result].sort((a, b) =>
          a.roleName.localeCompare(b.roleName)
        )
        dispatch(setRolesList(sortedData))
        setRowsList(sortedData)
      })
      .catch((error) => {
        setLoader(false)
      })
  }
  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getRoleList(companyId)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageTopRef.current.scrollTop = 0
  }
  const gotoDrawer = (event) => {
    setEdited(event)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
    pageTopRef.current.scrollTop = 0
  }

  const [state, setState] = React.useState({
    right: false,
  })

  const toggleDrawer = (anchor, open, switchVal, details, load) => {
    if (load) {
      getDecodeValue()
    }
    setDetails(details)
    setEdited(switchVal)
    setState({ ...state, [anchor]: open })
  }

  useEffect(() => {
    getDecodeValue()
  }, [])

  const checkAddEdit = (item, index) => {
    switch (item) {
      case 'add':
        return <AddNewRole toggleDrawer={toggleDrawer} />
      case 'view':
        return (
          <>
            <ViewRoles details={details} gotoDrawer={gotoDrawer}></ViewRoles>
          </>
        )
      case 'edit':
        return (
          <>
            <EditRole
              toggleDrawer={toggleDrawer}
              details={details}
              gotoDrawer={gotoDrawer}
            ></EditRole>
          </>
        )
    }
  }
  return (
    <Container maxWidth="xl">
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <React.Fragment>
        <Drawer
          PaperProps={{
            sx: { width: '25%' },
          }}
          variant="persistent"
          hideBackdrop
          anchor={'right'}
          open={state['right']}
        >
          <div>
            {checkPermission(
              [
                PERMISSIONS.masterRoleUpdate,
                PERMISSIONS.serviceproviderRoleUpdate,
                PERMISSIONS.resellerRoleUpdate,
                PERMISSIONS.customerRoleUpdate,
              ],
              commonState.currentCompanyDetails
            ) ? (
              edited == 'edit' ? (
                <Tooltip title="Back">
                  <IconButton
                    aria-label="back"
                    onClick={() => gotoDrawer('view')}
                    style={{
                      marginLeft: '-2px',
                      float: 'left',
                      borderRadius: 0,
                    }}
                  >
                    <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )
            ) : (
              ''
            )}
            {
              edited !== 'add' && <Tooltip title="Close">
                <IconButton
                  aria-label="delete"
                  onClick={() => toggleDrawer('right', false)}
                  style={{ float: 'right', borderRadius: 0 }}
                >
                  <CancelIcon color="error" size="large"></CancelIcon>
                </IconButton>
              </Tooltip>
            }

          </div>
          <div>
            {edited == 'view' ? (
              <h3
                style={{
                  display: 'flex',
                  marginTop: '-5px',
                  marginBottom: '0',
                }}
              >
                <div>
                  <h3
                    style={{
                      display: 'flex',
                      marginTop: '10px',
                      marginLeft: '10px',
                      marginBottom: '0',
                    }}
                  >
                    Role Details
                  </h3>
                  {checkPermission(
                    [
                      PERMISSIONS.masterRoleDelete,
                      PERMISSIONS.serviceproviderRoleDelete,
                      PERMISSIONS.resellerRoleDelete,
                      PERMISSIONS.customerRoleDelete,
                    ],
                    commonState.currentCompanyDetails
                  ) ? (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          fontSize: '15px',
                          color: ' #000000a1',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsOpen(true)}
                      >
                        <PersonRemoveIcon
                          style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            margin: '0 5px 0 15px',
                          }}
                        ></PersonRemoveIcon>
                        Delete Role
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </h3>
            ) : (
              ''
            )}
            {edited == 'edit' ? (
              <>
                <h3
                  style={{
                    display: 'flex',
                    marginTop: '-5px',
                    marginBottom: '0',
                  }}
                >
                  {/*{edited == 'editRole' ? <Avatar sx={{ bgcolor: 'skyblue' }}>J</Avatar>: ''}*/}
                  <div style={{ marginLeft: '10px' }}>
                    Update Role
                    <div style={{ display: 'flex' }}>
                      <div style={{ fontSize: '15px', color: ' #000000a1' }}>
                        Assign roles and permission
                      </div>
                    </div>
                  </div>
                </h3>
              </>
            ) : (
              ''
            )}
            {edited == 'add' ? (
              <>
                <DrawerHeader
                  header="Add Roles & Permissions"
                  onClick={() => toggleDrawer('right', false)}
                />
              </>
            ) : (
              ''
            )}
          </div>
          {checkAddEdit(edited)}
          <ConfirmationModal
            handleConfirm={() => handleDelete(details?.roleId)}
            title="Delete Role Confirmation"
            content={
              <span>
                Are you sure you wan to delete this role{' '}
                <b>{details?.roleName}</b>?
              </span>
            }
            isOpen={isOpen}
            handleClose={handleClose}
          />
        </Drawer>
      </React.Fragment>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '15px 0',
        }}
      >
        <Paper
          component="form"
          sx={{
            p: '2px 4px',
            m: '15px 0',
            display: 'flex',
            alignItems: 'center',
            width: 400,
          }}
        >
          {/*<IconButton sx={{ p: '10px' }} aria-label='menu'>*/}
          {/*  <MenuIcon />*/}
          {/*</IconButton>*/}
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            onChange={inputHandler}
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        {checkPermission(
          [
            PERMISSIONS.masterRoleCreate,
            PERMISSIONS.serviceproviderRoleCreate,
            PERMISSIONS.resellerRoleCreate,
            PERMISSIONS.customerRoleCreate,
          ],
          commonState.currentCompanyDetails
        ) ? (
          <Tooltip title="Add New Role">
            <ThemeButton
              text={'Add New Role'}
              variant="contained"
              size="medium"
              style={{ height: '32px', marginTop: '20px' }}
              startIcon={<AddIcon />}
              onClick={() => toggleDrawer('right', true, 'add')}
            ></ThemeButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Box>
      <TableContainer
        sx={{
          height:
            rowsPerPage == 5 || rowsList?.length <= 7
              ? 'max-content'
              : 'calc(95vh - 200px)',
        }}
        component={Paper}
        ref={pageTopRef}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 500 }}
          aria-label="custom pagination table"
        >
          <TableHead className="thead">
            <TableRow>
              <TableCell width={'10px'}>S.No.</TableCell>
              <TableCell>Role Name</TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <TableBody className="tbody">
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6}>
                  <SkeletonTableLoader></SkeletonTableLoader>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody className="tbody">
              {(rowsPerPage > 0
                ? rowsList?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : rowsList
              )?.map((row, index) => (
                <TableRow key={row?.roleName}>
                  <TableCell>{page * rowsPerPage + (index + 1)}</TableCell>
                  <TableCell
                    scope="row"
                    onClick={() => toggleDrawer('right', true, 'view', row)}
                  >
                    <Link> {row?.roleName}</Link>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {rowsList?.length === 0 ? (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} style={{ border: 'none' }}>
                    <h3>
                      <NoDataFoundCard
                        messages="No Such Roles Found"
                        borderNone={true}
                      />
                    </h3>
                  </TableCell>
                </TableRow>
              ) : (
                ''
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={rowsList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  )
}

export default RoleTable
