import {
  Grid,
  Paper,
  MenuItem,
  Container,
  InputBase,
  Tooltip,
  TableHead,
  Link,
  Chip,
  Stack,
  Menu,
  List,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import * as React from 'react'
import { createRef, useState } from 'react'
import SkeletonTableLoader from '../../../../common/components/loaders/skeletonLoader'
import IconButton from '@mui/material/IconButton'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import moment from 'moment/moment'
import {
  masterNotificationReceipientsByIdApi,
  masterNotificationTemplateCancelApi,
} from '../../../../api/master/masterAPI'
import ConformationModal from '../../../../common/components/custom/modal/modal'
import SearchIcon from '@mui/icons-material/Search'
import VisibilityIcon from '@mui/icons-material/Visibility'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import NoDataFoundCard from '../../../../common/components/cards/noDataFoundCard'
import TablePagination from '@mui/material/TablePagination'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DetailsModal from '../../../../common/components/custom/modal/detailsModal'
import ListItemIcon from '@mui/material/ListItemIcon'
import MailIcon from '@mui/icons-material/Mail'
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo'
import {
  ExportExcel,
  getNotificationName,
  getContactTypeName,
  getCompanyLevelName,
  checkPermission,
} from '../../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../../helpers/Constants'
import { useToastContext } from '../../../../App'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'

export function NotificationList(props) {
  const { dispatch, state: commonState } = useCommonRedux();
  const { toast } = useToastContext();
  const [showEdit, setShowEdit] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [recipientsList, setRecipientsList] = React.useState()
  const [exportRecipients, setExportRecipients] = React.useState([])
  const [edited, setEdited] = React.useState('')
  const [userDetails, setUserDetails] = React.useState('')
  const [state, setState] = React.useState({
    right: false,
  })
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const [resetPageOpen, setResetPageOpen] = useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [touched, setTouched] = React.useState(true)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [rowData, setRowData] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const pageTopRef = createRef()
  const open = Boolean(anchorEl)
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget)
    setRowData(row)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setIsOpen(false)
    setIsOpenDetails(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageTopRef.current.scrollTop = 0
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value))
    setPage(0)
    pageTopRef.current.scrollTop = 0
  }
  let inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase()
    const filteredData = props.rowsListFiltered.filter((el) => {
      if (lowerCase === '') {
        return el
      } else {
        return el?.subject.toLowerCase().includes(lowerCase)
      }
    })
    props.setRowsList(filteredData)
  }
  const handleNotificationById = (row, i) => {
    if (row) {
      props.setDetailsData(row)
      props?.setHideEdit(true)
    }
  }

  const handleReceptionsDetails = () => {
    if (rowData) {
      setSubmitLoader(true)
      masterNotificationReceipientsByIdApi(rowData.id)
        .then((response) => {
          setSubmitLoader(false)

          setRecipientsList(response.data.result)
          let exportMapData = response.data.result?.recipients.map(
            (dat, ind) => {
              return {
                'S.No.': ind + 1,
                'User Account': dat.email,
                'Company Name': dat.companyName,
                Level: getCompanyLevelName(dat.companyLevel),
                Contact: getContactTypeName(dat.contactType),
                Type: dat.isEmail ? 'Email' : 'In App',
                // 'Read Status': dat.isViewed ? 'Yes' : dat.isEmail ? '-' : 'No',
              }
            }
          )
          setExportRecipients(exportMapData)
          setShowEdit(false)
          setIsOpenDetails(true)
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast(error?.response?.data?.message, 'error')
        })
    }
  }

  const handleNotificationConformation = () => {
    setIsOpen(true)
  }
  const handleNotificationDelete = () => {
    if (rowData) {
      setSubmitLoader(true)
      masterNotificationTemplateCancelApi(rowData.id)
        .then((response) => {
          setSubmitLoader(false)
          props.getDecodeValue()
          toast.showToast(response?.data?.message, 'success')
          setRowData(null)
          handleClose()
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast(error?.response?.data?.message, 'error')
        })
    }
  }

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - props?.rowsList.length)
      : 0

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Container maxWidth="xl">
        {submitLoader ? <LoadingSection></LoadingSection> : ''}
        <Paper
          component="div"
          sx={{
            p: '2px 4px',
            m: '-50px 0 10px 0',
            display: 'flex',
            alignItems: 'center',
            width: 400,
          }}
        >
          {/*<IconButton sx={{ p: '10px' }} aria-label='menu'>*/}
          {/*  <MenuIcon />*/}
          {/*</IconButton>*/}
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by Subject "
            onChange={inputHandler}
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <TableContainer sx={{ height: 450 }} component={Paper} ref={pageTopRef}>
          <Table
            stickyHeader
            sx={{ minWidth: 500 }}
            aria-label="custom pagination table"
          >
            <TableHead className="thead">
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date & Time Sent</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Recipients</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {props?.loader ? (
              <TableBody className="tbody">
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6}>
                    <SkeletonTableLoader></SkeletonTableLoader>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="tbody">
                {(rowsPerPage > 0
                  ? props?.rowsList &&
                  props?.rowsList?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : props?.rowsList
                ).map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell width={'10px'}>
                      {page * rowsPerPage + (index + 1)}
                    </TableCell>
                    <TableCell scope="row">
                      <Link
                        onClick={() => {
                          handleNotificationById(row, index)
                        }}
                      >
                        {' '}
                        {getNotificationName(row.status)}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {row.sentDateTime && row.status == 3
                        ? moment(row.sentDateTime).format(
                          'YYYY MMM, DD - hh:mm a'
                        )
                        : ''}
                      {row.scheduledDateTime && row.status == 2
                        ? moment(row.scheduledDateTime).format(
                          'YYYY MMM, DD - hh:mm a'
                        )
                        : ''}
                      {/*{row.lastUpdatedDateTime ? moment(row.lastUpdatedDateTime).format('YYYY MMM, DD - hh:mm a') : ''}*/}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {row.isApp ? (
                          <Chip label="In App" variant="outlined" />
                        ) : (
                          ''
                        )}
                        {row.isEmail ? (
                          <Chip label="Email" variant="outlined" />
                        ) : (
                          ''
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>{row.subject}</TableCell>
                    <TableCell>
                      <Grid container columnSpacing={1} rowSpacing={1}>
                        <Grid item xs={5}>
                          <Stack direction="column" spacing={1}>
                            {row.companyLevel.map((mapDat) => (
                              <Chip
                                key={mapDat}
                                label={getCompanyLevelName(mapDat.companyLevel)}
                                variant="outlined"
                              />
                            ))}
                          </Stack>
                        </Grid>
                        <Grid item xs={5}>
                          <Stack direction="column" spacing={1}>
                            {row.contactType.map((mapDat) => (
                              <Chip
                                key={mapDat}
                                label={getContactTypeName(mapDat.contactType)}
                              />
                            ))}
                          </Stack>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {row?.status == 3 ||
                        ((row?.status == 2 || row?.status == 1) &&
                          !row?.isCancel) ? (
                        <MoreVertIcon
                          id="basic-button"
                          onClick={(e) => handleClick(e, row)}
                          style={{
                            width: '32px',
                            height: '32px',
                            cursor: 'pointer',
                          }}
                        />
                      ) : (
                        ''
                      )}

                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        elevation={1}
                        onClose={handleClose}
                      >
                        {rowData?.status == 3 ? (
                          <MenuItem onClick={() => handleReceptionsDetails()}>
                            <Tooltip title="View Recipients">
                              <>
                                <VisibilityIcon color="primary" />
                                View Recipients
                              </>
                            </Tooltip>
                          </MenuItem>
                        ) : (
                          ''
                        )}
                        {checkPermission(
                          [PERMISSIONS.masterNotificationDelete],
                          commonState.currentCompanyDetails
                        ) &&
                          (rowData?.status == 2 || rowData?.status == 1) &&
                          !rowData?.isCancel ? (
                          <MenuItem
                            onClick={() => handleNotificationConformation()}
                          >
                            <Tooltip
                              title={
                                rowData?.status == 1
                                  ? 'Delete Notification'
                                  : 'Cancel Notification'
                              }
                            >
                              <>
                                <CancelPresentationIcon color="primary" />
                                {rowData?.status == 1
                                  ? 'Delete Notification'
                                  : 'Cancel Notification'}
                              </>
                            </Tooltip>
                          </MenuItem>
                        ) : (
                          ''
                        )}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {props?.rowsList.length === 0 ? (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} style={{ border: 'none' }}>
                      <h3
                        style={{
                          margin: '0px auto',
                          textAlign: 'center',
                        }}
                      >
                        <NoDataFoundCard
                          messages="No Notification Created"
                          borderNone={true}
                        />
                      </h3>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={props?.rowsList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ConformationModal
          handleConfirm={() => handleNotificationDelete()}
          title="Confirmation"
          content={`Are you sure want to ${rowData?.status == 1 ? 'delete notification' : 'cancel notification'
            }  ?`}
          isOpen={isOpen}
          handleClose={handleClose}
        />

        <DetailsModal
          maxWidth={'lg'}
          title={
            <div>
              Recipients Details
              <ExportExcel
                fileName={
                  rowData?.subject +
                  '_' +
                  moment(new Date()).format('YYYY-MM-DD_HH:mm')
                }
                excelData={exportRecipients}
              />
            </div>
          }
          content={
            <>
              <List
                sx={{ width: '100%', mt: '-20px', bgcolor: 'background.paper' }}
              >
                <Tooltip title="Mail Notification">
                  <FormControlLabel
                    sx={{ marginLeft: '8px' }}
                    control={
                      <Checkbox checked={recipientsList?.isEmail} disabled />
                    }
                    label={
                      <ListItemIcon sx={{ mt: '8px' }}>
                        <MailIcon /> Mail
                      </ListItemIcon>
                    }
                  />
                </Tooltip>
                <Tooltip title="App Notification">
                  <FormControlLabel
                    sx={{ marginLeft: '8px' }}
                    control={
                      <Checkbox checked={recipientsList?.isApp} disabled />
                    }
                    label={
                      <ListItemIcon sx={{ mt: '8px' }}>
                        <PersonalVideoIcon /> In App
                      </ListItemIcon>
                    }
                  />
                </Tooltip>
                <TableContainer sx={{ height: 380 }} component={Paper}>
                  <Table
                    stickyHeader
                    sx={{ minWidth: 400 }}
                    aria-label="custom pagination table"
                  >
                    <TableHead className="thead">
                      <TableRow>
                        <TableCell>User Account</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell>Type</TableCell>
                        {/* <TableCell>Status</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbody">
                      {recipientsList?.recipients?.map((mapDat, index) => (
                        <TableRow hover key={index}>
                          <TableCell scope="row">{mapDat?.email}</TableCell>
                          <TableCell scope="row">
                            {mapDat?.companyName}
                          </TableCell>
                          <TableCell scope="row">
                            {getCompanyLevelName(mapDat?.companyLevel)}
                          </TableCell>
                          <TableCell scope="row">
                            {getContactTypeName(mapDat?.contactType)}
                          </TableCell>
                          <TableCell scope="row">
                            {mapDat?.isEmail ? (
                              <Tooltip title="Mail Notification">
                                <ListItemIcon sx={{ mr: '20px' }}>
                                  <MailIcon />
                                  <span style={{ width: '70px' }}>Mail</span>
                                </ListItemIcon>
                              </Tooltip>
                            ) : (
                              <Tooltip title="App Notification">
                                <ListItemIcon sx={{ mr: '20px' }}>
                                  <PersonalVideoIcon />
                                  <span style={{ width: '70px' }}>In App</span>
                                </ListItemIcon>
                              </Tooltip>
                            )}
                          </TableCell>
                          {/* <TableCell scope="row">
                            <Tooltip
                              title={mapDat?.isViewed ? 'Viewed' : 'Not View'}
                            >
                              {mapDat?.isViewed ? (
                                <ListItemIcon>
                                  <DraftsIcon />
                                </ListItemIcon>
                              ) : (
                                <ListItemIcon>
                                  <MarkAsUnreadIcon sx={{ color: '#aaf4aa' }} />
                                </ListItemIcon>
                              )}
                            </Tooltip>
                          </TableCell> */}
                        </TableRow>
                      ))}

                      {recipientsList?.recipients.length > 0 && (
                        <TableRow style={{ height: 30 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      {recipientsList?.recipients.length === 0 ? (
                        <TableRow style={{ height: 30 * emptyRows }}>
                          <TableCell colSpan={6} style={{ border: 'none' }}>
                            <h3
                              style={{
                                margin: '0px auto',
                                padding: '20px 0',

                                textAlign: 'center',
                              }}
                            >
                              <NoDataFoundCard
                                messages="No Notification found"
                                borderNone={true}
                              />
                            </h3>
                          </TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/*{recipientsList?.recipients.map(mapDat => (*/}
                {/*  <ListItemButton>*/}
                {/*    <Tooltip title={(mapDat?.isViewed || (mapDat?.isEmail && !mapDat?.isViewed))? 'Viewed' : 'Not View'}>*/}
                {/*      {(mapDat?.isViewed || (mapDat?.isEmail && !mapDat?.isViewed)) ?*/}
                {/*        <ListItemIcon >*/}
                {/*            <DraftsIcon />*/}
                {/*        </ListItemIcon> :*/}
                {/*        <ListItemIcon >*/}
                {/*            <MarkAsUnreadIcon sx={{color: '#aaf4aa'}} />*/}
                {/*        </ListItemIcon>}*/}
                {/*    </Tooltip>*/}
                {/*    <ListItemText sx={{ ml: '-20px' }} id='email' primary={mapDat?.email} />*/}
                {/*    {mapDat?.isEmail ?*/}
                {/*      <Tooltip title='Mail Notification'>*/}
                {/*        <ListItemIcon sx={{ mr: '20px' }}>*/}
                {/*          <MailIcon />*/}
                {/*          <span style={{width: '70px'}}>Mail</span>*/}
                {/*        </ListItemIcon>*/}
                {/*      </Tooltip>:*/}
                {/*      <Tooltip title='App Notification'>*/}
                {/*        <ListItemIcon sx={{ mr: '20px' }}>*/}
                {/*          <PersonalVideoIcon />*/}
                {/*          <span style={{width: '70px'}}>In App</span>*/}
                {/*        </ListItemIcon>*/}
                {/*      </Tooltip>*/}
                {/*    }*/}

                {/*  </ListItemButton>*/}
                {/*))}*/}
              </List>
            </>
          }
          isOpen={isOpenDetails}
          handleClose={handleClose}
        />
      </Container>
    </>
  )
}

export default NotificationList
