// eslint-disable react/button-has-type */
// eslint-disable jsx-a11y/label-has-associated-control */
import {
  Container,
  Grid,
  Box,
  Button,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { useState, useEffect } from 'react'

// mui icons
import BackupIcon from '@mui/icons-material/Backup'
import '../steppers.css'
// css
import './appearance.css'

// components
import { uploadLogo } from '../../../../../api/post/postApi'
import { useSelector } from 'react-redux'
import { appearance, formDetailsState } from '../../../../../store/slice/stepperSlice'
import { requiredLabel } from '../../../../../helpers/HelperFunctions'
import { useToastContext } from '../../../../../App'
import { useCommonRedux } from '../../../../../store/middlewares/CommonRedux'
import {
  setLogoURL,
  setSkeletonLoading,
} from '../../../../../store/slice/commonSlice'

function Appearance(props) {
  const { dispatch, state: commonState } = useCommonRedux()
  const { toast } = useToastContext()
  const [uploadlogo, setuploadlogo] = useState(null)
  const [uploadlogoURL, setuploadlogoURL] = useState(null)

  const logouploadhandler = (e) => {
    let file_size = e.target.files[0].size
    if (file_size < 2097152) {
      setuploadlogo(e.target.files[0])
      var url = URL.createObjectURL(e.target.files[0])
      dispatch(appearance(''))
      dispatch(setLogoURL(''))
      setuploadlogoURL(url)
    } else {
      toast.showToast('Image should be less than 2MB only', 'error')
    }
  }

  useEffect(() => {
    setuploadlogoURL(state.appearance.logoUrl)
  }, [])

  const companyid = localStorage.getItem('companyid')

  var logoformdata = new FormData()
  logoformdata.append('CompanyId', companyid)
  logoformdata.append('Logo', uploadlogo)

  // generic preloader

  const state = useSelector(formDetailsState)
  const uploadlogofunction = async () => {
    if (uploadlogo && uploadlogo.size < 2097152) {
      dispatch(setSkeletonLoading(true))
      try {
        const data = await uploadLogo(logoformdata, props?.config)
        dispatch(appearance(data.data.result.logoUrl))
        dispatch(setLogoURL(data.data.result.logoUrl))
        toast.showToast('Logo is added successfully')
        dispatch(setSkeletonLoading(false))
      } catch (err) {
        if (err) {
          toast.showToast('Logo file supports only JPG, JPEG and PNG', 'error')
          dispatch(setSkeletonLoading(false))
        }
      }
    } else {
      toast.showToast('Image should be less than 2MB only', 'error')
    }
  }

  const getcompanyid = localStorage.getItem('companyid')

  const changelogo = () => {
    setuploadlogoURL('')
    dispatch(appearance(''))
    dispatch(setLogoURL(''))
  }
  return (
    <Container>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Box className="stepper-title-containers">
            <h3>Appearance</h3>
            <Box className="stepper-main-contianer1 stepper-border-style stepper-box-shadow-style">
              <Box className="upload-btn-container-style">
                {requiredLabel('Logo')}
                &emsp;
                <div>
                  <Button
                    disableElevation
                    sx={{
                      width: '90px',
                      height: '90px',
                      borderRadius: '50%',
                      border: '2px dashed #4682b4',
                      backgroundColor: 'transparent',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    variant="contained"
                    component="label"
                  >
                    {commonState.isSkeletonLoading ? (
                      <CircularProgress size={40} />
                    ) : uploadlogoURL == '' ? (
                      <>
                        {' '}
                        <Tooltip title="Add logo">
                          <BackupIcon sx={{ width: '200px' }} />
                        </Tooltip>
                        <input
                          onChange={(e) => logouploadhandler(e)}
                          type="file"
                          accept="image/*"
                          inputProps={{ accept: 'image/*' }}
                          hidden
                        />{' '}
                      </>
                    ) : (
                      <img
                        src={uploadlogoURL}
                        width="100%"
                        height="100px"
                        onClick={changelogo}
                        style={{ objectFit: 'contain', borderRadius: '50%' }}
                      ></img>
                    )}
                  </Button>
                </div>
                &emsp;
                <div className="input-info-label">
                  File size must be under 2 mb (Image Format: PNG or JPEG or
                  JPG)
                  <br />
                </div>
              </Box>

              <Box className="Appearance-btn-container-style">
                <Box className="Appearance-btn-container-style-1">
                  <Button
                    disabled={!uploadlogo}
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={uploadlogofunction}
                  >
                    confirm
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pl: '20px', color: 'red' }}>
        {state.appearance.logoUrl == '' && props.logohelpertext}
      </Box>
    </Container>
  )
}

export default Appearance
