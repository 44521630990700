import { Box, Button, Grid, TextField, Container } from '@mui/material'
import * as React from 'react'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import SkeletonTableLoader from '../../../../common/components/loaders/skeletonLoader'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import { postConfigurationSBCPrefix } from '../../../../api/post/postApi'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../../helpers/Constants'
import { useToastContext } from '../../../../App'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'

export function SBCPrefix(props) {
  const { dispatch, state: commonState } = useCommonRedux();
  const { toast } = useToastContext();
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [touched, setTouched] = React.useState(true)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const formik = useFormik({
    initialValues: {
      companyId: '',
      sbcPrefix: props?.configData?.sbcPrefix,
    },
    initialTouched: false,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      postConfigurationSBCPrefix(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          setSubmitLoader(false)
          setShowEdit(false)
          props?.setHideEdit(false)
          props.refreshData(response)
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast(error?.response?.data?.message, 'error')
        })
    },
  })

  const showEditButton = (e) => {
    if (e) {
      if (props?.configData?.sbcPrefix) {
        formik.setFieldValue('sbcPrefix', props?.configData?.sbcPrefix)
      }
    } else {
      formik.resetForm()
    }
    setShowEdit(e)
  }
  useEffect(() => {
    formik.errors = {}
  }, [])
  useEffect(() => {
    formik.setTouched({}, touched)
  }, [touched, formik?.errors])

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Container>
        {loader ? (
          <div
            style={{
              padding: '5px 10px 0 20px',
            }}
          >
            <SkeletonTableLoader></SkeletonTableLoader>
          </div>
        ) : (
          <Grid container item>
            <Grid item md={10} sm={10} xs={10}>
              <form onSubmit={formik.handleSubmit}>
                <Grid item md={12} sm={12} xs={12}>
                  <Box className="stepper-input-label-margin">
                    <label className="stepper-label-style">Sbc Prefix</label>
                    <TextField
                      disabled={!showEdit}
                      id="sbcPrefix"
                      name="sbcPrefix"
                      inputProps={{
                        minLength: 1,
                        maxLength: 15,
                      }}
                      onChange={formik.handleChange}
                      value={formik.values?.sbcPrefix}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    <div style={{ color: 'red', position: 'absolute' }}>
                      {formik.errors?.sbcPrefix
                        ? formik.errors.sbcPrefix
                        : null}
                    </div>
                  </Box>
                </Grid>
                {showEdit ? (
                  <Box
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '5px',
                      background: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    className="stepper-input-label-margin"
                  >
                    <Button
                      disabled={!(formik.isValid && formik.dirty)}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ margin: '0 5px' }}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        showEditButton(false)
                        props?.setHideEdit(false)
                      }}
                      type="button"
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{ margin: '0 5px' }}
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </form>
            </Grid>
            <Grid item md={2} sm={2} xs={2}>
              {checkPermission(
                [
                  PERMISSIONS.masterSettingUpdate,
                  PERMISSIONS.serviceproviderSettingUpdate,
                  PERMISSIONS.resellerSettingUpdate,
                  PERMISSIONS.customerSettingUpdate,
                ],
                commonState.currentCompanyDetails
              ) ? (
                !showEdit && !props?.hideEdit ? (
                  <div
                    style={{
                      padding: '0 25px 0 0',
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        showEditButton(true)
                        props?.setHideEdit(true)
                      }}
                      aria-label="delete"
                      style={{
                        float: 'right',
                        borderRadius: 0,
                        fontSize: '18px',
                        marginTop: '35px',
                      }}
                    >
                      <EditIcon
                        style={{
                          fontSize: '14px',
                          verticalAlign: 'middle',
                          margin: '0 0px 0 10px',
                        }}
                      />
                      Edit
                    </IconButton>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  )
}

export default SBCPrefix
