import axios from 'axios'
import { history } from '../../common/components/router/HistoryRouter'
import { ENV_URLS } from '../../helpers/Constants'

// Base Axios API Client
export const createApiInstance = ({
  baseURL,
  headers,
  patch = false,
  download = false,
}) => {
  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem(
        ENV_URLS.TOKEN_IDENTIFIER
      )}`,
      Accept: 'application/json',
      'Content-Type': patch
        ? 'application/json-patch+json'
        : 'application/json',
    },
    responseType: download ? 'blob' : 'json',
  })

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { status } = error.response
        if (status === 401) {
          localStorage.clear()
          history.replace('/login')
        } else if (status === 404) {
          history.replace('/404')
        }
      }
      return Promise.reject(error)
    }
  )

  return api
}
