import Box from '@mui/material/Box'
import { Button, Grid, TextField, Typography } from '@mui/material'
import * as React from 'react'
import { useFormik } from 'formik'
import jwt_decode from 'jwt-decode'
import { useLocation, useSearchParams } from 'react-router-dom'
import LoadingSection from '../../../common/components/loaders/loader.js'
import {
  paymentCardPatchApi,
  paymentGetAllApi,
} from '../../../api/payment/paymentAPI.js'
import moment from 'moment/moment'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions.js'
import { PERMISSIONS } from '../../../helpers/Constants.js'
import { useToastContext } from '../../../App.js'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux.js'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
}

function EditPaymentCard(props) {
  const { toast } = useToastContext();
  const { dispatch, state: commonState } = useCommonRedux();
  const validatetoken = localStorage.getItem('sd_cp.jwt:tkn')
  const config = {
    // 'Accept' : 'application/json',
    'Content-Type': 'application/json-patch+json',
    Authorization: `Bearer ${validatetoken}`,
  }
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      id: props?.details?.paymentAccountId,
      firstName: props?.details?.cardAccount?.firstName,
      lastName: props?.details?.cardAccount?.lastName,
      expriationDate: props?.details?.cardAccount?.expriationDate,
      expirationMonth: moment(
        props?.details?.cardAccount?.expriationDate
      ).format('MM'),
      expirationYear: moment(
        props?.details?.cardAccount?.expriationDate
      ).format('YYYY'),
    },
    // validationSchema: PaymentFormValidation,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      try {
        let requestBodies = []
        paymentGetAllApi(
          companyId,
          getCompanyLevel(
            searchParams.get('company_id'),
            location.pathname.slice(1)
          )
        ).then((res) => {
          const tempData = res.data.result.find(
            (o) => o.paymentAccountId === data.id
          )
          if (tempData.cardAccount.firstName !== data.firstName) {
            requestBodies.push({
              operationType: 0,
              path: '/firstName',
              op: 'replace',
              value: data.firstName,
            })
          }
          if (tempData.cardAccount.lastName !== data.lastName) {
            requestBodies.push({
              operationType: 0,
              path: '/lastName',
              op: 'replace',
              value: data.lastName,
            })
          }

          if (
            moment(tempData.cardAccount.expriationDate).format('MM') !==
            data.expirationMonth
          ) {
            requestBodies.push({
              operationType: 0,
              path: '/expirationMonth',
              op: 'replace',
              value: data.expirationMonth,
            })
          }
          if (
            moment(tempData.cardAccount.expriationDate).format('YYYY') !==
            data.expirationYear
          ) {
            requestBodies.push({
              operationType: 0,
              path: '/expirationYear',
              op: 'replace',
              value: data.expirationYear,
            })
          }
          paymentCardPatchApi(
            requestBodies,
            data,
            getCompanyLevel(
              searchParams.get('company_id'),
              location.pathname.slice(1)
            )
          )
            .then((response) => {
              setSubmitLoader(false)
              props.toggleDrawer('right', false, '', '', true, response)
            })
            .catch((error) => {
              setSubmitLoader(false)
              toast.showToast(error.response.data.message, 'error')
            })
        })
      } catch (er) { }
    },
  })

  const onChangeRole = (e) => {
    props?.toggleDrawer('right', false)
  }
  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Box role="presentation">
        <div
          style={{
            display: 'flex',
            width: '100%',

            justifyContent: 'space-between',
            padding: '5px 10px 0 20px',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              columnSpacing={4}
              rowSpacing={3}
              style={{ paddingBottom: '100px' }}
            >
              <Grid item md={6} sm={6} xs={6}>
                <Typography variant="label">
                  First Name &nbsp; <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="firstName"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.firstName ? formik.errors.firstName : null}
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Typography variant="label">
                  Last Name &nbsp; <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="lastName"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.lastName ? formik.errors.lastName : null}
                </div>
              </Grid>

              {/*<Grid item md={6} sm={6} xs={12}>*/}
              {/*  <Typography variant="label">Expiration Date &nbsp; <span  style={{ color: 'red' }}>*</span></Typography>*/}
              {/*  <TextField*/}
              {/*    id="expriationDate"*/}
              {/*    name="expriationDate"*/}
              {/*    onChange={formik.handleChange}*/}
              {/*    value={formik.values.expriationDate.slice(0, 10)}*/}
              {/*    fullWidth*/}
              {/*    InputProps={{*/}
              {/*      style: {*/}
              {/*        height: '35px',*/}
              {/*      },*/}
              {/*    }}*/}
              {/*  />*/}
              {/*  <div style={{ color: 'red', position: 'absolute' }}>*/}
              {/*    {formik.errors.expriationDate*/}
              {/*      ? formik.errors.expriationDate*/}
              {/*      : null}*/}
              {/*  </div>*/}
              {/*</Grid>*/}

              <Grid item md={4} sm={4} xs={12}>
                <Typography variant="label">
                  Expiration Month &nbsp;{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="expirationMonth"
                  name="expirationMonth"
                  onChange={formik.handleChange}
                  value={formik.values.expirationMonth}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.expirationMonth
                    ? formik.errors.expirationMonth
                    : null}
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <Typography variant="label">
                  Expiration Year &nbsp; <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="expirationYear"
                  name="expirationYear"
                  onChange={formik.handleChange}
                  value={formik.values.expirationYear}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.expirationYear
                    ? formik.errors.expirationYear
                    : null}
                </div>
              </Grid>
            </Grid>
            {/*<Box sx={{ position: 'fixed', bottom: '10px', background: '#fff' }}>*/}
            {/*  <Button type="submit">update ACH profile</Button>*/}
            {/*</Box>*/}
            <Box
              style={{ position: 'fixed', bottom: '10px', background: '#fff' }}
              className="stepper-payemnt-buttoncontainer"
            >
              {checkPermission(
                [
                  PERMISSIONS.masterBillingUpdate,
                  PERMISSIONS.serviceproviderBillingUpdate,
                  PERMISSIONS.resellerBillingUpdate,
                  PERMISSIONS.customerBillingUpdate,
                ],
                commonState.currentCompanyDetails
              ) ? (
                <Button
                  disabled={!(formik.isValid && formik.dirty)}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="large"
                >
                  Update Card Details
                </Button>
              ) : (
                ''
              )}
            </Box>
          </form>
        </div>
      </Box>
    </>
  )
}

export default EditPaymentCard
