import Box from '@mui/material/Box'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import {
  masterPrivilegesApi,
  masterRolePostApi,
} from '../../../api/master/masterAPI'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import jwt_decode from 'jwt-decode'
import LoadingSection from '../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  getCompanyLevel,
  requiredLabel,
} from '../../../helpers/HelperFunctions'
import { RoleFormValidation } from '../../../validations/UserManagementValidation'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

//--- states for add roles Management checkboxes --

const allManagements = []

export function AddNewRole(props) {
  const { dispatch, state: commonState } = useCommonRedux()
  const { toast } = useToastContext()
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [permissionList, setPermissionList] = React.useState([])
  const [allManagement, setAllManagement] = React.useState(allManagements)
  const [serviceErrorMessage, setServiceErrorMessage] = React.useState(null)
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const getPrivilegesApi = () => {
    masterPrivilegesApi(
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    ).then((response) => {
      let billingFilter
      if (location.pathname.slice(1) == 'master') {
        billingFilter = response.data.result
      } else if (
        location.pathname.slice(1) == 'serviceprovider' &&
        !commonState.currentCompanyDetails?.isAltigenBilling
      ) {
        billingFilter = response.data.result.filter(
          (datFilt) => datFilt.groupName !== 'Billing'
        )
      } else if (
        location.pathname.slice(1) == 'serviceprovider' &&
        commonState.currentCompanyDetails?.isAltigenBilling
      ) {
        billingFilter = response.data.result.filter(
          (datFilt) =>
            datFilt.groupName !== 'Reseller Billing' &&
            datFilt.groupName !== 'Customer Billing'
        )
      } else if (
        location.pathname.slice(1) == 'reseller' &&
        commonState.currentCompanyDetails?.isAltigenBilling
      ) {
        billingFilter = response.data.result.filter(
          (datFilt) => datFilt.groupName !== 'Customer Billing'
        )
      } else if (
        location.pathname.slice(1) == 'reseller' &&
        !commonState.currentCompanyDetails?.isAltigenBilling &&
        commonState.currentCompanyDetails?.isRevParent
      ) {
        billingFilter = response.data.result.filter(
          (datFilt) => datFilt.groupName !== 'Billing'
        )
      } else if (
        location.pathname.slice(1) == 'customer' &&
        commonState.currentCompanyDetails?.isAltigenBilling
      ) {
        billingFilter = response.data.result
      } else {
        billingFilter = response.data.result.filter(
          (datFilt) => !datFilt.groupName.includes('Billing')
        )
      }
      setPermissionList(billingFilter)
      billingFilter.forEach((dat) => {
        allManagements.push(true)
      })
    })
  }
  const handlePrivileges = (e, index1, index2) => {
    setPermissionList((prev) => {
      let newState = [...prev]

      newState[index1].privileges[index2].isAssigned = e.target.checked

      //--- update bind with view ---

      if (
        (newState[index1].privileges[index2].displayName
          .toLowerCase()
          .includes('update') ||
          newState[index1].privileges[index2].displayName
            .toLowerCase()
            .includes('create') ||
          newState[index1].privileges[index2].displayName
            .toLowerCase()
            .includes('delete')) &&
        e.target.checked
      ) {
        newState[index1].privileges.forEach((item, i) => {
          item.displayName.toLowerCase().includes('view') &&
            (item.isAssigned = true)
        })
      }
      if (
        newState[index1].privileges[index2].displayName
          .toLowerCase()
          .includes('view') &&
        !e.target.checked
      ) {
        newState[index1].privileges.forEach((item, i) => {
          ;(item.displayName.toLowerCase().includes('update') ||
            item.displayName.toLowerCase().includes('create') ||
            item.displayName.toLowerCase().includes('delete')) &&
            (item.isAssigned = false)
        })
        let checkedRoles = newState[index1].privileges.filter(
          (o) => o.isAssigned == true
        )
        // if(checkedRoles.length > 1){
        setAllManagement((prevState) => {
          if (newState[index1].privileges.length > 1) {
            prevState[index1] = false
          }
          return prevState
        })
        // }
      }
      if (
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('serviceprovider')
      ) {
        newState.forEach((dat) => {
          if (dat.groupName == 'ServiceProvider Company') {
            dat.privileges.forEach((privDat) => {
              if (privDat.value.toLowerCase().includes('view')) {
                privDat.isAssigned = true
              }
            })
          }
        })
      }
      if (
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('reseller')
      ) {
        newState.forEach((dat) => {
          if (dat.groupName == 'Reseller Company') {
            dat.privileges.forEach((privDat) => {
              if (privDat.value.toLowerCase().includes('view')) {
                privDat.isAssigned = true
              }
            })
          }
        })
      }

      if (
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('customer')
      ) {
        newState.forEach((dat) => {
          if (dat.groupName == 'Customer Company') {
            dat.privileges.forEach((privDat) => {
              if (privDat.value.toLowerCase().includes('view')) {
                privDat.isAssigned = true
              }
            })
          }
        })
      }
      if (
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('customer.product.create') ||
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('customer.product.update')
      ) {
        newState.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('reseller.product.view')
            ) {
              privDatService.isAssigned = true
            }
            if (
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.view')
            ) {
              privDatService.isAssigned = true
            }
            if (
              privDatService.value.toLowerCase().includes('master.product.view')
            ) {
              privDatService.isAssigned = true
            }
          })
        })
      }
      if (
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('reseller.product.create') ||
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('reseller.product.update')
      ) {
        newState.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.view')
            ) {
              privDatService.isAssigned = true
            }
            if (
              privDatService.value.toLowerCase().includes('master.product.view')
            ) {
              privDatService.isAssigned = true
            }
          })
        })
      }
      if (
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('serviceprovider.product.create') ||
        newState[index1].privileges[index2].value
          .toLowerCase()
          .includes('serviceprovider.product.update')
      ) {
        newState.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value.toLowerCase().includes('master.product.view')
            ) {
              privDatService.isAssigned = true
            }
          })
        })
      }
      let checkedRoles = newState[index1].privileges.filter(
        (o) => o.isAssigned == true
      )
      let noOfCheckedItems = checkedRoles.length

      if (
        (noOfCheckedItems === newState[index1].privileges.length &&
          e.target.checked) ||
        (noOfCheckedItems === newState[index1].privileges.length - 1 &&
          !e.target.checked)
      ) {
        setAllManagement((prevState) =>
          prevState.map((item, i) => (i === index1 ? !item : item))
        )
      }

      return newState
    })
  }

  const handleAllPrivileges = (e, role, index) => {
    setPermissionList((prev) => {
      let newState = [...prev]
      let isNotEmptyRole = role != null || role != undefined || role != []
      if (e.target.checked) {
        newState.forEach((dat) => {
          if (dat.groupName.toLowerCase().includes('serviceprovider')) {
            if (dat.groupName == 'ServiceProvider Company') {
              dat.privileges.forEach((privDat) => {
                if (privDat.value.toLowerCase().includes('view')) {
                  privDat.isAssigned = true
                }
              })
            }
          }
        })
        newState.forEach((dat) => {
          if (dat.groupName.toLowerCase().includes('reseller')) {
            if (dat.groupName == 'Reseller Company') {
              dat.privileges.forEach((privDat) => {
                if (privDat.value.toLowerCase().includes('view')) {
                  privDat.isAssigned = true
                }
              })
            }
          }
        })
        newState.forEach((dat) => {
          if (dat.groupName.toLowerCase().includes('customer')) {
            if (dat.groupName == 'Customer Company') {
              dat.privileges.forEach((privDat) => {
                if (privDat.value.toLowerCase().includes('view')) {
                  privDat.isAssigned = true
                }
              })
            }
          }
        })
        newState.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('customer.product.create') ||
              privDatService.value
                .toLowerCase()
                .includes('customer.product.update')
            ) {
              newState.forEach((datProductService) => {
                datProductService.privileges.forEach(
                  (privProudctDatService) => {
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('master.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('serviceprovider.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('reseller.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                  }
                )
              })
            }
          })
        })
        newState.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('reseller.product.create') ||
              privDatService.value
                .toLowerCase()
                .includes('reseller.product.update')
            ) {
              newState.forEach((datProductService) => {
                datProductService.privileges.forEach(
                  (privProudctDatService) => {
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('serviceprovider.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('master.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                  }
                )
              })
            }
          })
        })
        newState.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.create') ||
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.update')
            ) {
              newState.forEach((datProductService) => {
                datProductService.privileges.forEach(
                  (privProudctDatService) => {
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('master.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                  }
                )
              })
            }
          })
        })
      }
      if (!e.target.checked && isNotEmptyRole) {
        newState[index].privileges.map(
          (obj, i) => (obj.isAssigned = e.target.checked)
        )
        setAllManagement((prevState) =>
          prevState.map((item, i) => (i === index ? !item : item))
        )
      } else if (e.target.checked && isNotEmptyRole) {
        newState[index].privileges.map(
          (obj, i) => (obj.isAssigned = e.target.checked)
        )
        setAllManagement((prevState) =>
          prevState.map((item, i) => (i === index ? !item : item))
        )
      }
      return newState
    })
  }

  const checkSPCompanyView = (permission) => {
    let boolPermission = false
    const rolsSPFilter = permission.filter((rolChild, ind) => {
      if (rolChild.groupName.toLowerCase().includes('company')) {
        return rolChild.privileges
      }
    })
    rolsSPFilter.forEach((dat) => {
      if (dat.groupName.includes('Company')) {
        for (let i = 0; i < dat.privileges.length; i++) {
          if (
            dat.privileges[i].value.toLowerCase().includes('serviceprovider') &&
            dat.privileges[i].value.toLowerCase().includes('view')
          ) {
            boolPermission = dat.privileges.some((val) => val.isAssigned)
            break
            return boolPermission
          }
        }
      }
    })
    return boolPermission
  }
  const checkResellerCompanyView = (permission, level) => {
    let boolPermission = false
    const rolsSPFilter = permission.filter((rolChild, ind) => {
      if (rolChild.groupName.toLowerCase().includes('company')) {
        return rolChild.privileges
      }
    })
    rolsSPFilter.forEach((dat) => {
      if (dat.groupName.includes('Company')) {
        for (let i = 0; i < dat.privileges.length; i++) {
          if (
            dat.privileges[i].value.toLowerCase().includes('reseller') &&
            dat.privileges[i].value.toLowerCase().includes('view')
          ) {
            boolPermission = dat.privileges.some((val) => val.isAssigned)
            return boolPermission
            break
          }
        }
      }
    })
    return boolPermission
  }
  const checkCustomerCompanyView = (permission) => {
    let boolPermission = false
    const rolsSPFilter = permission.filter((rolChild, ind) => {
      if (rolChild.groupName.toLowerCase().includes('company')) {
        return rolChild.privileges
      }
    })
    rolsSPFilter.forEach((dat) => {
      if (dat.groupName.includes('Company')) {
        for (let i = 0; i < dat.privileges.length; i++) {
          if (
            dat.privileges[i].value.toLowerCase().includes('customer') &&
            dat.privileges[i].value.toLowerCase().includes('view')
          ) {
            boolPermission = dat.privileges.some((val) => val.isAssigned)

            return boolPermission
            break
          }
        }
      }
    })
    return boolPermission
  }

  const checkServiceSelectedBool = (permission) => {
    let boolPermission = true
    permission.forEach((datService) => {
      datService.privileges.forEach((privDatService) => {
        if (
          privDatService.value
            .toLowerCase()
            .includes('customer.product.create') ||
          privDatService.value.toLowerCase().includes('customer.product.update')
        ) {
          if (privDatService.isAssigned) {
            permission.forEach((datProductService) => {
              datProductService.privileges.forEach((privProudctDatService) => {
                if (
                  privProudctDatService.value
                    .toLowerCase()
                    .includes('reseller.product.view')
                ) {
                  if (!privProudctDatService.isAssigned) {
                    toast.showToast(
                      'Please select View Service permission on Reseller Service Management',
                      'error'
                    )
                    boolPermission = false
                  }
                }
              })
            })
          }
        }
      })
    })
    permission.forEach((datService) => {
      datService.privileges.forEach((privDatService) => {
        if (
          privDatService.value
            .toLowerCase()
            .includes('reseller.product.create') ||
          privDatService.value.toLowerCase().includes('reseller.product.update')
        ) {
          if (privDatService.isAssigned) {
            permission.forEach((datProductService) => {
              datProductService.privileges.forEach((privProudctDatService) => {
                if (
                  privProudctDatService.value
                    .toLowerCase()
                    .includes('serviceprovider.product.view')
                ) {
                  if (!privProudctDatService.isAssigned) {
                    toast.showToast(
                      'Please select View Service permission on Serviceprovider Service Management',
                      'error'
                    )
                    boolPermission = false
                  }
                }
              })
            })
          }
        }
      })
    })
    permission.forEach((datService) => {
      datService.privileges.forEach((privDatService) => {
        if (
          privDatService.value
            .toLowerCase()
            .includes('serviceprovider.product.create') ||
          privDatService.value
            .toLowerCase()
            .includes('serviceprovider.product.update')
        ) {
          if (privDatService.isAssigned) {
            permission.forEach((datProductService) => {
              datProductService.privileges.forEach((privProudctDatService) => {
                if (
                  privProudctDatService.value
                    .toLowerCase()
                    .includes('master.product.view')
                ) {
                  if (!privProudctDatService.isAssigned) {
                    toast.showToast(
                      'Please select View Service permission on Master Service Management',
                      'error'
                    )
                    boolPermission = false
                  }
                }
              })
            })
          }
        }
      })
    })
    return boolPermission
  }

  useEffect(() => {
    getPrivilegesApi()
  }, [])

  const formik = useFormik({
    initialValues: {
      roleName: '',
      companyId: '',
      description: '',
      privileges: [],
      roleStatus: 1,
    },
    validationSchema: RoleFormValidation,
    onSubmit: (data) => {
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId

      checkSPCompanyView(permissionList, 'serviceprovider')
      checkResellerCompanyView(permissionList, 'reseller')
      checkCustomerCompanyView(permissionList, 'customer')
      checkServiceSelectedBool(permissionList, 'customer')
      let privileges = permissionList.map((rol) => {
        let rols = rol.privileges.map((rolChild) => {
          if (rolChild.isAssigned) {
            return rolChild.id
          }
        })
        if (rols) return rols
      })
      let concatPrivileges = privileges.join(',').split(',')
      let removeBlankArray = concatPrivileges.filter((filt) => filt)
      data.privileges = []
      data.privileges = removeBlankArray
      if (removeBlankArray.length > 0) {
        if (
          (checkSPCompanyView(permissionList, 'serviceprovider') &&
            checkResellerCompanyView(permissionList, 'reseller') &&
            checkCustomerCompanyView(permissionList, 'customer') &&
            'master' == location.pathname.slice(1)) ||
          (checkSPCompanyView(permissionList, 'serviceprovider') &&
            checkResellerCompanyView(permissionList, 'reseller') &&
            checkCustomerCompanyView(permissionList, 'customer') &&
            'serviceprovider' == location.pathname.slice(1)) ||
          (checkResellerCompanyView(permissionList, 'reseller') &&
            checkCustomerCompanyView(permissionList, 'customer') &&
            'reseller' == location.pathname.slice(1)) ||
          (checkCustomerCompanyView(permissionList, 'customer') &&
            'customer' == location.pathname.slice(1))
        ) {
          if (checkServiceSelectedBool(permissionList)) {
            setSubmitLoader(true)
            masterRolePostApi(
              data,
              getCompanyLevel(
                searchParams.get('company_id'),
                location.pathname.slice(1)
              )
            )
              .then((response) => {
                toast.showToast(response.data.message, 'success')
                props?.toggleDrawer('right', true)
                props.toggleDrawer('right', false, '', '', true)
                setSubmitLoader(false)
              })
              .catch((error) => {
                setSubmitLoader(false)
                toast.showToast(error.response.data.message, 'error')
              })
          } else {
            //toast.showToast(serviceErrorMessage, 'error');
          }
        } else {
          toast.showToast('Company View should be selected', 'error')
        }
      } else {
        toast.showToast('Minimum one permission should be selected', 'error')
      }
    },
  })

  const disabledCompanyView = (company) => {}
  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Box role="presentation">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            padding: '5px 10px 90px 20px',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={4} rowSpacing={3}>
              <Grid item md={6} sm={6} xs={6}>
                {requiredLabel('Role Name')}
                <TextField
                  id="roleName"
                  name="roleName"
                  onChange={formik.handleChange}
                  value={formik.values.roleName}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div
                  style={{
                    color: 'red',
                    position: 'absolute',
                    marginBottom: '5px',
                  }}
                >
                  {formik.errors.roleName && formik.errors.roleName}
                </div>
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{ marginBottom: '10px' }}
              >
                <Typography variant="label">Assign Role</Typography>
              </Grid>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  height: 'calc(90vh - 190px)',
                  overflowX: 'hidden',
                  marginBottom: '10px',
                }}
              >
                {permissionList?.map((rol, index) => {
                  return (
                    <>
                      {rol.groupName.includes('Dashboard') ? (
                        ''
                      ) : (
                        <Grid key={rol?.groupName + '_' + index + 'grid'}>
                          <FormControlLabel
                            style={{
                              borderTop: '1px solid silver',
                              width: '100%',
                            }}
                            key={rol?.groupName + '_' + index + 'form'}
                            label={rol?.groupName.replace(
                              /ServiceProvider/g,
                              'Service Provider'
                            )}
                            control={
                              <Checkbox
                                key={rol?.groupName + '_' + index + 'checkbox'}
                                indeterminate={
                                  rol.privileges.filter((is) => is.isAssigned)
                                    .length != rol.privileges.length &&
                                  rol.privileges.filter((is) => is.isAssigned)
                                    .length != 0
                                }
                                checked={
                                  rol.privileges.filter((is) => is.isAssigned)
                                    .length == rol.privileges.length
                                }
                                disabled={rol?.groupName.includes('Company')}
                                onChange={(event) =>
                                  handleAllPrivileges(event, rol, index)
                                }
                              />
                            }
                          />
                          <Box
                            key={rol?.groupName + '_' + index + 'box'}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              ml: 3,
                            }}
                          >
                            {rol?.privileges?.map((childRol, index2) => {
                              return (
                                <FormControlLabel
                                  key={
                                    index +
                                    '_' +
                                    index2 +
                                    '_' +
                                    childRol.id +
                                    'child'
                                  }
                                  label={childRol?.displayName?.replace(
                                    /ServiceProvider/g,
                                    'Service Provider'
                                  )}
                                  control={
                                    <Checkbox
                                      key={
                                        index +
                                        '_' +
                                        index2 +
                                        '_' +
                                        childRol.id +
                                        'checkbox'
                                      }
                                      disabled={childRol?.value.includes(
                                        'company.view'
                                      )}
                                      checked={childRol?.isAssigned}
                                      onChange={(event) =>
                                        handlePrivileges(event, index, index2)
                                      }
                                    />
                                  }
                                />
                              )
                            })}
                          </Box>
                        </Grid>
                      )}
                    </>
                  )
                })}
              </Grid>
            </Grid>
            <Box
              style={{
                position: 'fixed',
                marginTop: '10px',
                background: 'white',
                display: 'flex',
              }}
            >
              <Button
                disabled={!(formik.isValid && formik.dirty)}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                Add New Role
              </Button>
              <div style={{ marginLeft: '20px' }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    props.toggleDrawer('right', false, '', '', false)
                  }
                  disableElevation
                  size="large"
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </form>
        </div>
      </Box>
    </>
  )
}

export default AddNewRole
