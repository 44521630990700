import Box from '@mui/material/Box'
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material'
import * as React from 'react'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import jwt_decode from 'jwt-decode'
import { useLocation, useSearchParams } from 'react-router-dom'
import LoadingSection from '../../../common/components/loaders/loader'
import { paymentBankPostApi } from '../../../api/payment/paymentAPI'
import ReusableTextfield from '../../../common/components/textField/textField'
import * as Yup from 'yup'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
}
const initialValues = {
  companyId: '',
  accountName: '',
  accountNumber: '',
  routingNumber: '',
  accountType: '',
  bankName: '',
  checkNumber: '',
}

const FormValidation = Yup.object().shape({
  accountName: Yup.string().required('* Required'),
  accountNumber: Yup.string().required('* Required'),
  routingNumber: Yup.string().required('* Required'),
  bankName: Yup.string().required('* Required'),
  checkNumber: Yup.string().required('* Required'),
})

function AddNewPaymentBank(props) {
  const { toast } = useToastContext();
  const { dispatch, state: commonState } = useCommonRedux();
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const onChangeRole = (e) => {
    props?.toggleDrawer('right', false)
  }

  const [accounttype, setaccounttype] = useState('CHECKING')

  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Box role="presentation">
        <div
          style={{
            display: 'flex',
            width: '100%',

            justifyContent: 'space-between',
            padding: '5px 10px 0 20px',
          }}
        >
          <Formik
            initialValues={{
              ...initialValues,
            }}
            validationSchema={FormValidation}
            onSubmit={(data) => {
              const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
              const decoded = jwt_decode(decodeToken)
              let companyId = searchParams.get('company_id')
              if (!companyId) {
                companyId = decoded?.company_id
              }
              const newdata = {
                companyId: companyId,
                accountName: data.accountNumber,
                accountNumber: data.accountNumber,
                routingNumber: data.routingNumber.toString(),
                accountType: accounttype,
                bankName: data.bankName,
                checkNumber: data.checkNumber,
              }
              setSubmitLoader(true)

              paymentBankPostApi(
                newdata,
                getCompanyLevel(
                  searchParams.get('company_id'),
                  location.pathname.slice(1)
                )
              )
                .then((response) => {
                  props.toggleDrawer('right', false, '', '', true, response)
                  setSubmitLoader(false)
                })
                .catch((error) => {
                  setSubmitLoader(false)
                  toast.showToast(error.response.data.message, 'error')
                })
            }}
          >
            <Form>
              <Grid
                container
                columnSpacing={4}
                rowSpacing={3}
                style={{ paddingBottom: '100px' }}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <ReusableTextfield
                    id="Account Name"
                    name="accountName"
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    required
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <ReusableTextfield
                    id="Account Number"
                    name="accountNumber"
                    placeholder="**************"
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    required
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <ReusableTextfield
                    id="Routing Number"
                    name="routingNumber"
                    placeholder=""
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    required
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="label">
                    Account Type &nbsp; <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Select
                    error={accounttype != '' ? false : true}
                    style={{ width: '100%' }}
                    size="small"
                    value={accounttype}
                    name="accountType"
                    onChange={(e) => setaccounttype(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem
                      onClick={() => setaccounttype('CHECKING')}
                      value="CHECKING"
                    >
                      Checking
                    </MenuItem>
                    <MenuItem
                      onClick={() => setaccounttype('SAVINGS')}
                      value="SAVINGS"
                    >
                      Saving
                    </MenuItem>
                    h
                  </Select>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <ReusableTextfield
                    id="Bank Name"
                    name="bankName"
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                    required
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <ReusableTextfield
                    id="Cheque Number"
                    name="checkNumber"
                    required
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {checkPermission(
                [
                  PERMISSIONS.masterBillingCreate,
                  PERMISSIONS.serviceproviderBillingCreate,
                  PERMISSIONS.resellerBillingCreate,
                  PERMISSIONS.customerBillingCreate,
                ],
                commonState.currentCompanyDetails
              ) ? (
                <Box
                  style={{
                    position: 'fixed',
                    bottom: '10px',
                    background: '#fff',
                  }}
                  className="stepper-payemnt-buttoncontainer"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                  >
                    Add New Payment
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </Form>
          </Formik>
        </div>
      </Box>
    </>
  )
}

export default AddNewPaymentBank
