import {
  Avatar,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import CustomDataGrid from '../../../common/components/dataGrid/CustomDataGrid'
import { Add as AddIcon } from '@mui/icons-material'
import { styled } from '@mui/system'
import jwt_decode from 'jwt-decode'
import {
  setCompanyId,
  setCustomersList,
  setFilteredRows,
  setServiceProviderTrigger,
} from '../../../store/slice/homeSlice'
import StatusChip from '../../../common/components/mui/Chips'
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined'
import { useSearchParams } from 'react-router-dom'
import { getAllcustomers } from '../../../api/get/getApi'
import { CustomerColumn } from '../../../common/components/dataGrid/columns/HomeColumns'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  executeWithTryCatch,
  formatDate,
  isResponseSuccess,
} from '../../../helpers/HelperFunctions'
import DashboardLayout from '../../../common/layouts/DashboardLayout'
import { useHomeRedux } from '../Home'
import { setAllProducts, setIsLoading } from '../../../store/slice/commonSlice'
import {
  COMPANY_LEVELS,
  COMPANY_STATUS,
  COMPANY_STATUS_OBJ,
  TOAST_MESSAGES,
} from '../../../helpers/Constants'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'
import CustomerAnalytics from './components/CustomerAnalytics'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { motion } from 'framer-motion'
import ConfirmationModal from '../../../common/components/custom/modal/modal'
import { FlexBox } from '../../../common/styles/styled-components/StyledContainers'
import { AltigenLogoFlipLoader } from '../../../common/components/loaders/loader'
import { api_DeleteCustomerComany } from '../../../api/WebPortalAPI'

const FlexContainer = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
})

const Customer = (props) => {
  // hooks
  const { dispatch, state } = useHomeRedux()
  const { state: commonState } = useCommonRedux()
  const { toast } = useToastContext()
  const [searchparams, setSearchparams] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuItems, setMenuItems] = useState([])
  const [filters, setFilters] = useState([])
  const open = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState(null)
  const [isOpenDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  //lifeCycles
  useEffect(() => {
    if (!commonState.isDirectCustomer) {
      getCompanyId()
    }
  }, [state.isToggleCustomer])

  useEffect(() => {
    state.searchValue?.customer && getFilteredRows()
  }, [state.searchValue?.customer])

  const getCompanyId = async () => {
    let companyId = ''
    dispatch(setAllProducts(null))
    dispatch(setServiceProviderTrigger('')) //avoid trigger service provider
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    if (decodeToken) {
      const decoded = jwt_decode(decodeToken)
      if (decoded) {
        companyId = searchparams.get('company_id')
        if (!companyId) {
          companyId = decoded?.company_id
        }
      }
    }
    const response = await getAllcustomers(companyId)
    dispatch(setCustomersList(response.data.result || []))
    dispatch(setCompanyId(companyId))
  }

  const AnchorLink = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
  }))

  const handleSignUpUrlOpen = (url) => {
    window.open(url, '_blank')
  }

  const handleCopy = async (url) => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: 'clipboard-write',
      })

      if (
        permissionStatus.state === 'granted' ||
        permissionStatus.state === 'prompt'
      ) {
        await navigator.clipboard.writeText(url)
        toast.showToast('Text copied to clipboard successfully!')
      } else {
        toast.showToast('Clipboard write permission denied', 'error')
      }
    } catch (err) {
      toast.showToast('Failed to copy text: ', 'error')
    }
    navigator.clipboard.writeText(url)
  }

  const handleOpenDeleteConfirmation = (row) => {
    setOpenDeleteConfirmation(true)
    setSelectedRow(row)
  }

  const renderCompanyName = (row, value) => {
    return [COMPANY_STATUS.Active, COMPANY_STATUS.ApprovalPending].includes(
      row?.companyStatus
    ) ? (
      <FlexBox>
        <Typography variant="tablePrimary">
          <Link
            underline="none"
            color="primary"
            onClick={() => props?.onRowClick(row)}
          >
            {value}
          </Link>
          <Tooltip title="Copy Login Url">
            <IconButton onClick={() => handleCopy(row?.loginUrl)}>
              <ContentCopyIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Tooltip>
        </Typography>
        {/* --- Delete Customer part --  */}
        <motion.div whileHover={{ scale: 1.3 }}>
          {commonState.isMaster && (
            <Tooltip title="Delete Customer" placement="right">
              <IconButton
                color="error"
                sx={{display: 'none'}}
                onClick={() => handleOpenDeleteConfirmation(row)}
              >
                <DeleteOutlineIcon color="error" sx={{ fontSize: 14 }} />
              </IconButton>
            </Tooltip>
          )}
        </motion.div>
      </FlexBox>
    ) : (
      <FlexBox>
        <Typography variant="tablePrimary">
          {value}&nbsp;
          <Tooltip title="Copy Signup Url">
            <IconButton onClick={() => handleCopy(row?.signupUrl)}>
              <ContentCopyIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Tooltip>
        </Typography>
        {/* --- Delete Customer part --  */}
        <motion.div whileHover={{ scale: 1.3 }}>
          {commonState.isMaster && (
            <Tooltip title="Delete Customer" placement="right">
              <IconButton
                color="error"
                sx={{display: 'none'}}
                onClick={() => handleOpenDeleteConfirmation(row)}
              >
                <DeleteOutlineIcon color="error" sx={{ fontSize: 14 }} />
              </IconButton>
            </Tooltip>
          )}
        </motion.div>
      </FlexBox>
    )
  }

  //render company
  const renderCompany = (params) => {
    const { row, value, id } = params
    return (
      <FlexContainer sx={{ gap: '1rem' }}>
        <Avatar alt="logo" src={row.logoUrl} sx={{ width: 24, height: 24 }} />
        {renderCompanyName(row, value, id)}
      </FlexContainer>
    )
  }

  //Render filter
  const renderFilter = (params) => {
    return (
      <>
        <FlexContainer>
          {params.colDef.headerName}
          <IconButton
            aria-label="filter list"
            size="small"
            onClick={(e) => handleClick(e, params.field)}
          >
            {state.columnFilters.status ? (
              <FilterAlt sx={{ color: 'white' }} />
            ) : (
              <FilterAltOutlined sx={{ color: 'white' }} />
            )}
          </IconButton>
          {open.status && (
            <StatusFilter
              open={open.status}
              handleClose={handleClose}
              handleClick={() => {}}
              anchorEl={anchorEl.status}
              items={state.menuItems}
            />
          )}
          {open.regDate && (
            <DateFilter
              open={open.regDate}
              handleClose={handleClose}
              handleClick={() => {}}
              anchorEl={anchorEl.regDate}
              items={state.menuItems}
            />
          )}
          {open.primaryContact && (
            <PrimaryContactFilter
              open={open.primaryContact}
              handleClose={handleClose}
              handleClick={() => {}}
              anchorEl={anchorEl.primaryContact}
              items={state.menuItems}
            />
          )}
        </FlexContainer>
      </>
    )
  }

  //Render Company Status
  const renderCompanyStatus = (statusId) => {
    const status = Object.keys(COMPANY_STATUS).find(
      (key) => COMPANY_STATUS[key] === statusId
    )

    return (
      <StatusChip
        label={
          statusId === COMPANY_STATUS.ApprovalPending
            ? 'Approval Pending'
            : status
        }
        variant="outlined"
        color={COMPANY_STATUS_OBJ[statusId].color}
        isSharp
      />
    )
  }

  //Filter in dataGrid
  const getFilteredRows = () => {
    const searchValue = state.searchValue?.customer?.toLowerCase()
    const filteredRows = state.customersList?.filter((row) => {
      const date = formatDate(new Date(row.createdDateTime))
      const regex = new RegExp(searchValue, 'i')
      return (
        row.name?.toLowerCase().includes(searchValue) ||
        row.primaryContactEmail?.toLowerCase().includes(searchValue) ||
        regex.test(date)
      )
    })
    dispatch(setFilteredRows(filteredRows))
  }

  //close
  const handleClose = () => {
    setAnchorEl(null)
  }

  //click
  const handleClick = (event, field) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    if (field == 'companyStatus') setMenuItems(COMPANY_STATUS_OBJ)
  }

  const handleDeleteResellerCustomer = async () => {
    setOpenDeleteConfirmation(false)
    dispatch(setIsLoading(true))
    // TODO: api call to delete customer
    executeWithTryCatch(async () => {
      const response = await api_DeleteCustomerComany({
        companyId: selectedRow.id,
      })
      if (isResponseSuccess(response)) {
        const customers = [...state.customersList]
        const index = customers.findIndex(
          (customer) => customer.id === selectedRow.id
        )
        if (index === -1) return
        customers.splice(index, 1)
        dispatch(setIsLoading(false))
        setTimeout(() => dispatch(setCustomersList(customers)), 1000)
        toast.showToast(TOAST_MESSAGES.CustomerDeleted)
      }
    }, handleDeleteError)
  }

  const handleDeleteError = (error) => {
    toast.showToast(
      error?.response?.data?.message ||
        TOAST_MESSAGES.ProblemWithDeletingCustomer,
      'error'
    )
    dispatch(setIsLoading(false))
  }

  const customerColumnProps = {
    renderFilter: renderFilter,
    renderCompany: renderCompany,
    renderCompanyStatus: renderCompanyStatus,
  }

  return (
    <Grid container>
      {commonState.isLoading && <AltigenLogoFlipLoader />}
      {state.customersList?.length > 0 && (
        <Grid item xs={12} sm={12} md={12}>
          <CustomerAnalytics customersList={state.customersList} />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12}>
        <DashboardLayout
          name="Customer"
          buttonStartIcon={<AddIcon />}
          isShowSearch={props.isShowSearch}
          handleButtonClick={(e) => props?.handleShowCompany(e, 'customer')}
          children={
            <CustomDataGrid
              columns={CustomerColumn(customerColumnProps)}
              data={state.customersList || []}
              searchValue={state.searchValue?.customer}
              filteredRows={state.filteredRows}
              noDataText="Customer"
            />
          }
        />
        <ConfirmationModal
          isOpen={isOpenDeleteConfirmation}
          title="Delete Customer"
          content={
            <span>
              Are you sure you want to delete <b>{selectedRow?.name}</b> ?
            </span>
          }
          btnOk="Delete Customer"
          btnCancel="Cancel"
          handleClose={() => setOpenDeleteConfirmation(false)}
          handleConfirm={handleDeleteResellerCustomer}
          size="sm"
        />
      </Grid>
    </Grid>
  )
}

export default Customer
