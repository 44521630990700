import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import * as React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  Stack,
  Typography,
} from '@mui/material'
import { masterRoleDetailApi } from '../../../api/master/masterAPI'
import { useEffect } from 'react'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import { useLocation, useSearchParams } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

export default function ViewRoles(props) {
  const { dispatch, state: commonState } = useCommonRedux()
  const [roleDetails, setRoleDetails] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const getRoleDetailsApi = (id) => {
    if (id) {
      setLoader(true)
      masterRoleDetailApi(
        id,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          const privileges = response.data.result
          let billingFilter = response.data.result

          if (location.pathname.slice(1) == 'master') {
            billingFilter = response.data.result
          } else if (
            location.pathname.slice(1) == 'serviceprovider' &&
            !commonState.currentCompanyDetails?.isAltigenBilling
          ) {
            const billingPreviligesFilter = privileges.privileges.filter(
              (datFilt) => datFilt.groupName !== 'Billing'
            )
            billingFilter.privileges = billingPreviligesFilter
          } else if (
            location.pathname.slice(1) == 'serviceprovider' &&
            commonState.currentCompanyDetails?.isAltigenBilling
          ) {
            const billingPreviligesFilter = privileges.privileges.filter(
              (datFilt) =>
                datFilt.groupName !== 'Reseller Billing' &&
                datFilt.groupName !== 'Customer Billing'
            )
            billingFilter.privileges = billingPreviligesFilter
          } else if (
            location.pathname.slice(1) == 'reseller' &&
            commonState.currentCompanyDetails?.isAltigenBilling
          ) {
            const billingPreviligesFilter = privileges.privileges.filter(
              (datFilt) => datFilt.groupName !== 'Customer Billing'
            )
            billingFilter.privileges = billingPreviligesFilter
          } else if (
            location.pathname.slice(1) == 'reseller' &&
            !commonState.currentCompanyDetails?.isAltigenBilling &&
            commonState.currentCompanyDetails?.isRevParent
          ) {
            const billingPreviligesFilter = privileges.privileges.filter(
              (datFilt) => datFilt.groupName !== 'Billing'
            )
            billingFilter.privileges = billingPreviligesFilter
          } else if (
            location.pathname.slice(1) == 'customer' &&
            commonState.currentCompanyDetails?.isAltigenBilling
          ) {
            const billingPreviligesFilter = privileges.privileges
            billingFilter.privileges = billingPreviligesFilter
          } else {
            const billingPreviligesFilter = privileges.privileges.filter(
              (datFilt) => !datFilt.groupName.includes('Billing')
            )
            billingFilter.privileges = billingPreviligesFilter
          }
          setRoleDetails(billingFilter)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }
  useEffect(() => {
    getRoleDetailsApi(props?.details?.roleId)
  }, [props?.details?.roleId])
  return (
    <Box role="presentation">
      {loader ? (
        <div
          style={{
            padding: '5px 10px 0 20px',
          }}
        >
          <SkeletonTableLoader></SkeletonTableLoader>
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              padding: '5px 0 0 10px',
            }}
          >
            <div
              style={{ display: 'flex', position: 'absolute', right: '70px' }}
            >
              {checkPermission(
                [
                  PERMISSIONS.masterRoleUpdate,
                  PERMISSIONS.serviceproviderRoleUpdate,
                  PERMISSIONS.resellerRoleUpdate,
                  PERMISSIONS.customerRoleUpdate,
                ],
                commonState.currentCompanyDetails
              ) ? (
                <div
                  onClick={() => props?.gotoDrawer('edit')}
                  style={{
                    margin: '10px -10px',
                    fontSize: '15px',
                    color: ' #000000a1',
                    cursor: 'pointer',
                  }}
                >
                  <EditIcon
                    style={{
                      fontSize: '12px',
                      verticalAlign: 'middle',
                      margin: '0 5px 0 15px',
                    }}
                  />
                  Edit Permissions
                  {/*<EditIcon  style={{fontSize:'12px',verticalAlign:'middle', margin: '0 5px 0 15px'}}></EditIcon>*/}
                  {/*Edit Roles*/}
                </div>
              ) : (
                ''
              )}
            </div>
            <h2 style={{ fontSize: '14px', marginLeft: '10px' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <label style={{ fontWeight: 'normal' }}>Role Name:</label>
                </Grid>
                <Grid item>
                  <label>{roleDetails?.roleName}</label>
                </Grid>
              </Grid>
            </h2>
          </div>
          <List>
            <div style={{ padding: '0px 20px' }}>
              <h2>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>Assigned Permission</div>
                </div>
              </h2>
              <div
                style={{
                  height: 'calc(90vh - 220px)',
                  overflowX: 'hidden',
                }}
              >
                <Stack
                  style={{ minWidth: '210px' }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {roleDetails?.privileges?.map((rol, index1) => (
                    <>
                      {rol.groupName.includes('Dashboard') ? (
                        ''
                      ) : (
                        <Accordion
                          style={{
                            margin: '1px',
                            boxShadow: 'none',
                            padding: 0,
                          }}
                          disableGutters
                          key={index1}
                        >
                          <AccordionSummary
                            style={{ margin: '-4px' }}
                            id={index1}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography>
                              {rol.groupName.replace(
                                /ServiceProvider/g,
                                'Service Provider'
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {rol?.privileges?.map((childRol, index2) => {
                              return (
                                <Box
                                  key={
                                    childRol.id +
                                    '_' +
                                    index1 +
                                    '_' +
                                    index2 +
                                    'box'
                                  }
                                >
                                  <FormControlLabel
                                    key={
                                      childRol.id + '_' + index1 + '_' + index2
                                    }
                                    label={childRol?.displayName.replace(
                                      /ServiceProvider/g,
                                      'Service Provider'
                                    )}
                                    name={childRol.id}
                                    control={
                                      <Checkbox
                                        disabled
                                        key={
                                          childRol.id +
                                          '_' +
                                          index1 +
                                          '_' +
                                          index2 +
                                          'checkbox'
                                        }
                                        checked={childRol?.isAssigned}
                                        onChange={(event) =>
                                          handlePrivileges(
                                            event,
                                            index1,
                                            index2,
                                            childRol
                                          )
                                        }
                                      />
                                    }
                                  />
                                </Box>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </>

                    // <Chip key={index} label={rol.groupName} variant="outlined"/>
                  ))}
                </Stack>
              </div>
            </div>
          </List>
        </>
      )}
    </Box>
  )
}
