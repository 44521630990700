import React from 'react'
import { useState } from 'react'

// css
import { styled } from '@mui/material/styles'

// components
import {
  Grid,
  Button,
  Box,
  Stack,
  CircularProgress,
  InputAdornment,
} from '@mui/material'

import Logo from '../../../assets/Altigen_logo_with_text.png'

// formik
import { Formik, Form } from 'formik'
import ReusableTextfield from '../../../common/components/textField/textField'
import * as Yup from 'yup'

// icons
import EmailIcon from '@mui/icons-material/Email'

// importing api
import { forgotPassword } from '../../../api/post/postApi'
import { useToastContext } from '../../../App'

const StyledTitle = styled('span')(({ theme }) => ({
  color: theme.typography.Title.color,
  fontSize: theme.typography.Title.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: 'left',
  lineHeight: '26px',
  fontStyle: 'normal',
  letterSpacing: '0px',
}))

const StyledSubTitle = styled('span')(({ theme }) => ({
  color: theme.typography.SubTitle.color,
  fontSize: theme.typography.SubTitle.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'left',
  lineHeight: '22px',
  fontStyle: 'normal',
  letterSpacing: '0px',
}))

const StyledPara = styled('span')(({ theme }) => ({
  color: theme.typography.Para.color,
  fontSize: theme.typography.Para.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'center',
  lineHeight: '22px',
  fontStyle: 'normal',
  letterSpacing: '0px',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

const initialValues = {
  emailAddress: '',
}

const FormValidation = Yup.object().shape({
  emailAddress: Yup.string().email('Invalid email.').required('Required'),
})

function ForgotPassword(props) {
  const { toast } = useToastContext();
  const [isloading, setisLoading] = useState(false)
  const [isRedirect, setIsRedirect] = useState(false)
  const [num, setNum] = useState(5)

  const forgotPasswordFunction = async (values) => {
    setisLoading(true)
    try {
      await forgotPassword(values).then((res) => {
        setMessage()
        forgotpasswordResponseFunction(res)
      })
    } catch (error) {
      setisLoading(false)
      toast.showToast(error?.response?.data?.message, 'error')
    }
  }

  const setMessage = () => {
    let num = 5
    const interval = setInterval(() => {
      num = num - 1
      setNum(num)
    }, 1000)
    setIsRedirect(true)
    setTimeout(() => props.changePage(false), 5000)
    setTimeout(() => {
      clearInterval(interval)
    }, 5000)
  }

  const forgotpasswordResponseFunction = (res) => {
    setisLoading(false)
    toast.showToast(res.data.message)
  }

  return (
    <div className="forgotpasswordPage_main_Container">
      <div className="forgotpasswordPage_Container">
        <Formik
          initialValues={{
            ...initialValues,
          }}
          validationSchema={FormValidation}
          onSubmit={(values) => {
            forgotPasswordFunction(values)
          }}
        >
          <Form>
            <div className="textfield_container">
              <Grid container rowSpacing={{ lg: 3, md: 3, sm: 2, xs: 1 }}>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="logo_container">
                    <img
                      src={Logo}
                      width="250px"
                      style={{ objectFit: 'cover' }}
                    ></img>
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <StyledTitle>
                    <span>Forgot Your Password?</span>
                  </StyledTitle>
                  <Stack spacing={2}>
                    <Box>
                      <StyledSubTitle>
                        <span>Please enter your credentials to proceed.</span>
                      </StyledSubTitle>
                    </Box>
                    {isRedirect ? (
                      <Box>
                        <StyledSubTitle>
                          <span>Redirecting to Login Page in {num}.</span>
                        </StyledSubTitle>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Stack>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <ReusableTextfield
                    size="small"
                    name="emailAddress"
                    id="Email"
                    InputProps={{
                      style: {
                        height: '35px',
                        fontSize: '12px',
                        paddingLeft: '8px',
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{ style: { fontSize: '12px' } }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: '4px',
                        width: '110px',
                      }}
                    >
                      {isloading ? (
                        <CircularProgress size="20px" sx={{ color: '#fff' }} />
                      ) : (
                        'Send'
                      )}{' '}
                    </Button>

                    <StyledPara>
                      <span onClick={() => props.changePage(false)}>
                        Already have an account? <b> Sign In</b>
                      </span>
                    </StyledPara>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default ForgotPassword
